import React from 'react';
import './HomePageSkeleton.css';

const HomePageSkeleton = () => {
  return (
    <div className="home-page">
      {/* Profile Completion Section Skeleton */}
      <div className="content-wrapper">
        <div className="profile-completion-section skeleton">
          <div className="profile-completion-header">
            <div className="profile-completion-title">
              <div className="skeleton-text skeleton-pulse" style={{ width: '200px', height: '24px' }}></div>
              <div className="skeleton-text skeleton-pulse" style={{ width: '100px', height: '24px' }}></div>
            </div>
            <div className="profile-completion-progress skeleton-pulse"></div>
          </div>
          
          <div className="completion-tasks">
            {[1, 2, 3, 4].map((i) => (
              <div key={i} className="completion-task skeleton">
                <div className="completion-task-content">
                  <div className="skeleton-text skeleton-pulse" style={{ width: '80%', height: '20px' }}></div>
                  <div className="skeleton-text skeleton-pulse" style={{ width: '60%', height: '16px', marginTop: '8px' }}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Projects Section Skeleton */}
      <div className="content-wrapper">
        <div className="projects-section">
          <div className="skeleton-text skeleton-pulse" style={{ width: '200px', height: '32px', margin: '24px 20px' }}></div>
          <div className="project-cards">
            {[1, 2].map((i) => (
              <div key={i} className="project-card skeleton">
                <div className="project-card-logo skeleton-pulse"></div>
                <div className="project-card-content">
                  <div className="skeleton-text skeleton-pulse" style={{ width: '70%', height: '24px' }}></div>
                  <div className="skeleton-text skeleton-pulse" style={{ width: '40%', height: '16px', marginTop: '8px' }}></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* How It Works Section Skeleton */}
        <div className="how-it-works-section">
          <div className="how-it-works-content">
            <div className="skeleton-text skeleton-pulse" style={{ width: '200px', height: '32px', margin: '24px 20px' }}></div>
            <div className="steps-container">
              {[1, 2, 3].map((i) => (
                <div key={i} className="step-card skeleton">
                  <div className="skeleton-text skeleton-pulse" style={{ width: '60%', height: '20px' }}></div>
                  <div className="skeleton-text skeleton-pulse" style={{ width: '90%', height: '16px', marginTop: '12px' }}></div>
                  <div className="skeleton-text skeleton-pulse" style={{ width: '80%', height: '16px', marginTop: '8px' }}></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageSkeleton;