import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { signOut } from "firebase/auth";
import LoginPage from '../../pages/LoginPage/LoginPage';
import { auth } from '../../authentication/Firebase';
import useAuth from '../../hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/theme.css';
import '../../styles/styles.css';
import './Header.css';
import { FaSearch, FaTimes, FaUserCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import MedlaLogo from '../../assets/medla_logo.svg';
import MedlaLogoMobile from '../../icon.svg';
import { useLanguage } from '../../context/LanguageContext';

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [userProfileId, setUserProfileId] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [modalType, setModalType] = useState('login');
  const { t } = useTranslation();
  const location = useLocation();
  const { language } = useLanguage();
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [userEmail, setUserEmail] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  
  const searchPlaceholders = [
    t('components.Header.search.placeholders.accommodation'),    // "Find apartments near me..."
    t('components.Header.search.placeholders.restaurants'),      // "Search for Italian restaurants..."
    t('components.Header.search.placeholders.services'),         // "Looking for an electrician..."
    t('components.Header.search.placeholders.events'),           // "Upcoming events this weekend..."
    t('components.Header.search.placeholders.professionals'),    // "Find a certified plumber..."
  ];

  // Add this effect to rotate placeholders
  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((current) => 
        (current + 1) % searchPlaceholders.length
      );
    }, 3000); // Change placeholder every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };
  
  const handleCloseModal = () => setShowModal(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Clear user-related states immediately
      setUserProfileId(null);
      setProfileImageUrl(null);
      // Force a page reload to clear all states
      window.location.reload();
      // Note: The navigate('/') call is not needed since we're reloading the page
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search${searchQuery.trim() ? `?query=${encodeURIComponent(searchQuery)}` : ''}`);
  };

  const handleMobileSearch = (e) => {
    e.preventDefault();
    navigate(`/search${searchQuery.trim() ? `?query=${encodeURIComponent(searchQuery)}` : ''}`);
    setShowMobileSearch(false);
  };

  const [showMobileSearch, setShowMobileSearch] = useState(false);

  useEffect(() => {
    const fetchUserProfileId = async () => {
      if (user && auth.currentUser) {
        try {
          const idToken = await auth.currentUser.getIdToken(true);
          
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-profile/${user.uid}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${idToken}`,
            }
          });
          
          if (response.ok) {
            const data = await response.json();
            setUserProfileId(data.uid || user.uid);
            setProfileImageUrl(data.PublicData_y?.profileImageUrl);
            setUserEmail(data.email || data.EmailAddress || user.email);
            // Set company name if available
            setCompanyName(data.Title || null);
          } else {
            setUserProfileId(user.uid);
            setUserEmail(user.email);
          }
        } catch (error) {
          console.error('Error fetching user profile ID:', error);
          setUserProfileId(user.uid);
          setUserEmail(user.email);
        }
      }
    };

    fetchUserProfileId();
  }, [user]);

  useEffect(() => {
    if (user) {
      handleCloseModal();
    }
  }, [user]);

  const getHomeLink = () => {
    return language === 'en' ? '/en' : '/';
  };

  const getProfileLink = () => {
    const languagePrefix = location.pathname.startsWith('/en') ? '/en' : '';
    return `${languagePrefix}/profile/${userProfileId || user.uid}`;
  };

  // Add this new useEffect to reset search when location changes
  useEffect(() => {
    // Only reset if we're not on the search page
    if (!location.pathname.includes('/search')) {
      setSearchQuery('');
    }
  }, [location.pathname]);

  return (
    <header className="header sticky-header">
      <nav className="navbar navbar-light">
        <div className="header-left-group">
          <button 
            className="mobile-search-trigger"
            onClick={() => setShowMobileSearch(true)}
            aria-label={t('components.Header.search.ariaLabel.open')}
            data-testid="mobile-search-trigger"
          >
            <FaSearch className="search-icon" />
          </button>
        </div>

        <Link to={getHomeLink()} className="navbar-brand">
          <img
            src={MedlaLogo}
            alt={t('components.Header.accessibility.logo')}
            className="logo"
          />
          <img
            src={MedlaLogoMobile}
            alt={t('components.Header.accessibility.logo')}
            className="logo-mobile"
          />
        </Link>

        <div className="header-center">
          <form onSubmit={handleSearch} className="search-form">
            <div className="search-input-wrapper">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={searchPlaceholders[placeholderIndex]}
                className="search-input"
              />
              <div className="ai-indicator">AI</div>
              <button type="submit" className="search-button">
                <FaSearch className="search-icon" />
              </button>
            </div>
          </form>
        </div>

        <div className="header-right">
          {user ? (
            <div className="user-menu">
              <div className="avatar">
                {profileImageUrl ? (
                  <img src={profileImageUrl} alt={t('components.Header.accessibility.profileImage')} className="avatar-img" />
                ) : user.photoURL ? (
                  <img src={user.photoURL} alt={t('components.Header.accessibility.profileImage')} className="avatar-img" />
                ) : (
                  <div className="avatar-placeholder">
                    {user.email?.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
              <div className="dropdown-menu">
                <div className="profile-info-section">
                  <div className="section-heading">
                    {t('components.Header.auth.profileInfo')}
                  </div>
                  {companyName && (
                    <div className="user-info company-name">
                      {companyName}
                    </div>
                  )}
                  <div className="user-info user-email">
                    {userEmail}
                  </div>
                </div>
                <div className="menu-divider"></div>
                <Link to={getProfileLink()} className="menu-item">
                  {t('components.Header.auth.profile')}
                </Link>
                <div className="menu-divider"></div>
                <Link as="button" onClick={handleLogout} className="menu-item">
                  {t('components.Header.auth.logout')}
                </Link>
              </div>
            </div>
          ) : (
            <>
              {/* Desktop auth buttons */}
              <div className="desktop-auth">
                <button 
                  className="btn btn-primary" 
                  onClick={() => handleShowModal('login')}
                >
                  {t('components.Header.auth.login')}
                </button>
                <button 
                  className="btn btn-secondary" 
                  onClick={() => handleShowModal('register')}
                >
                  {t('components.Header.auth.register')}
                </button>
              </div>
              
              {/* Mobile auth button */}
              <button 
                className="mobile-auth-trigger"
                onClick={() => handleShowModal('login')}
                aria-label={t('components.Header.auth.login')}
              >
                <FaUserCircle className="auth-icon" />
              </button>
            </>
          )}
        </div>
      </nav>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t(`components.Header.modal.${modalType}Title`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginPage 
            modalType={modalType} 
            onModalTypeChange={setModalType} 
          />
        </Modal.Body>
      </Modal>

      {showMobileSearch && (
        <div className="mobile-search-modal">
          <div className="mobile-search-header">
            <button 
              className="mobile-search-close"
              onClick={() => setShowMobileSearch(false)}
              aria-label={t('components.Header.search.ariaLabel.close')}
              data-testid="mobile-search-close"
            >
              <FaTimes />
            </button>
            <form onSubmit={handleMobileSearch} className="search-form" style={{margin: 0}}>
              <div className="search-input-wrapper">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={t('components.Header.search.mobilePlaceholder')}
                  className="search-input"
                  autoFocus
                />
                <div className="ai-indicator">
                  <span>AI</span>
                </div>
                <button type="submit" className="search-button">
                  <FaSearch className="search-icon" />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
