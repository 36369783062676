// src/hooks/useAuth.js
import { useEffect, useState, useCallback } from "react";
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { auth } from "../authentication/Firebase";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const login = useCallback(async (idToken) => {
    try {
      console.log("Attempting to exchange token...");
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/exchange-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });

      if (!response.ok) {
        console.error('Token exchange failed:', await response.text());
        throw new Error('Failed to exchange token');
      }

      const { customToken } = await response.json();
      console.log("Token exchanged successfully. Signing in with custom token...");

      await signInWithCustomToken(auth, customToken);
      console.log("Sign in with custom token successful.");

      // The onAuthStateChanged listener will update the user state
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  }, []);

  return { user, loading, login };
};

export default useAuth;
