export default {
  pages: {
    search: {
      title: 'Search',
      navigation: {
        previous: 'Previous',
        next: 'Next',
        pageInfo: 'Page {{page}} of {{totalPages}}'
      },
      views: {
        showList: 'Show list',
        showMap: 'Show map'
      },
      errors: {
        fetchCompanies: 'Could not fetch companies'
      }
    },
    home: {
      title: 'Home',
      hero: {
        title: 'Do local business.',
        subtitle: 'Find local companies in green industry projects.',
        cta: 'Search companies'
      },
      profileCompletion: {
        title: 'Improve Profile',
        percentComplete: '{{percent}}% complete',
        completed: 'Profile is complete!',
        viewProfile: 'View my profile',
        updateInfo: 'Update information',
        groups: {
          profile: 'Profile',
          contact: 'Contact',
          company: 'Company'
        }
      },
      profileChecks: {
        profileImageUrl: 'Profile image',
        title: 'Company name',
        address: 'Address',
        companyContact: 'Contact person',
        contactNumber: 'Phone',
        contactEmail: 'Email',
        companyNumber: 'Organization number',
        description: 'Description',
        descriptions: {
          profileImageUrl: 'Upload an image',
          title: 'Enter company name',
          address: 'Enter address',
          companyContact: 'Enter contact person',
          contactNumber: 'Enter phone number',
          contactEmail: 'Enter email address',
          companyNumber: 'Enter organization number',
          description: 'Describe your business'
        }
      },
      projects: {
        title: 'Projects',
        velinga: {
          name: 'Velinga',
          location: 'Tidaholm'
        },
        tomasliden: {
          name: 'Tomasliden',
          location: 'Norsjö'
        }
      },
      howItWorks: {
        title: 'How it works',
        steps: {
          register: {
            title: '1. Register your company',
            description: 'It\'s free and you\'re not committed to anything when you register.'
          },
          describe: {
            title: '2. Describe your company',
            description: 'Describe your services to get the most out of the platform. Well-described companies generate more traffic and business.'
          },
          complete: {
            title: '3. Done',
            description: 'Your company is now visible on Medla, good luck with your business!'
          }
        }
      },
      welcomeModal: {
        title: 'Welcome to Medla!',
        step: 'One last step...',
        description: 'Add a brief description of your business and your contact information.',
        tip: {
          label: 'Tip',
          text: 'This makes it easier for customers to find and contact you!'
        },
        actions: {
          close: 'Close',
          completeProfile: 'Complete your profile'
        }
      }
    },
    login: {
      title: {
        login: 'Log in',
        register: 'Register',
      },
      subtitle: {
        login: 'Log in to Medla',
        register: 'Create an account'
      },
      form: {
        email: {
          label: 'Email address',
          placeholder: 'name@example.com'
        },
        button: {
          login: 'Send login link',
          register: 'Create account',
          loading: {
            login: 'Sending link...',
            register: 'Creating account...'
          }
        },
        toggle: {
          login: 'Don\'t have an account? ',
          register: 'Already have an account? ',
          linkText: {
            login: 'Register',
            register: 'Log in'
          }
        }
      },
      terms: {
        login: {
          text: 'We\'ll send a secure login link to your email. By continuing, you agree to our ',
          linkText: 'terms'
        },
        register: {
          text: 'We\'ll send a verification link to your email. By continuing, you agree to our ',
          linkText: 'terms'
        },
        modal: {
          title: 'Terms of Service',
          close: 'Close'
        }
      },
      success: {
        title: {
          login: 'Waiting for you to log in',
          register: 'Waiting for you to activate your account'
        },
        subtitle: {
          login: 'Go to your email and click the link to log in',
          register: 'Go to your email and click the link to activate your account'
        },
        steps: {
          title: 'What happens now?',
          login: [
            'Open your email',
            'Click the link in the email',
            'You\'ll be logged in automatically'
          ],
          register: [
            'Open your email',
            'Click the link to activate',
            'You\'ll be logged in automatically'
          ]
        },
        noEmail: {
          message: 'Can\'t find the email? Check your spam folder or',
          retry: 'try again'
        }
      },
      errors: {
        generic: 'An error occurred: {{error}}'
      },
      messages: {
        linkSent: 'A login link has been sent to your email address. Please check your inbox.'
      }
    },
    finishregister: {
      verifying: {
        title: 'Medla is verifying your email'
      },
      verified: {
        title: 'Verification complete!',
        message: 'You can now return to the window where you logged in.',
        continueMessage: 'Or click',
        here: 'here',
        continueInWindow: 'to continue in this window.'
      },
      error: {
        title: 'An error occurred',
        message: 'Could not verify your email. Please try again.',
        backToLogin: 'Return to login page'
      }
    },
    adminLogin: {
      title: 'Stig Dashboard Login',
      description: 'Please log in with your @peerdigital.se Google account',
      actions: {
        signInWithGoogle: 'Sign in with Google'
      },
      errors: {
        loginFailed: 'Login failed: {{error}}',
        generic: 'Error: {{message}}'
      }
    },
    profile: {
      loading: 'Loading...',
      alerts: {
        pendingReview: 'Your profile is being reviewed. It is usually published within 24 hours.',
        unavailable: 'This profile is not available.',
        updateSuccess: 'Profile has been updated!',
        updateError: 'An error occurred during update: {{error}}',
        uploadError: 'Failed to upload image'
      },
      actions: {
        edit: 'Edit profile',
        cancelEdit: 'Cancel editing',
        save: 'Save changes',
        saving: 'Saving...',
        cancel: 'Cancel',
        translate: "Translate to English",
        showOriginal: "Show Original",
        translating: "Translating..."
      },
      sections: {
        about: {
          title: 'About'
        },
        contact: {
          showEmail: 'Show email',
          showPhone: 'Show phone',
          email: 'Email',
          phone: 'Phone'
        }
      },
      buttons: {
        showEmail: 'Show email',
        showPhone: 'Show phone'
      }
    },
    faq: {
      title: 'Frequently Asked Questions',
      questions: {
        q1: 'Who uses Medla?',
        q2: 'What projects is Medla used for?',
        q3: 'Is it easy to get started?',
        q4: 'How can Medla be free for small businesses?',
        q5: 'Does Medla cost anything?',
        q7: 'Who is behind Medla?',
        q8: 'How do I report a company on Medla?'
      },
      answers: {
        a1: 'Municipalities wanting to showcase and make their business community more accessible, project developers wanting to highlight benefits and anchor their establishments, construction contractors needing goods and services for projects, and local businesses wanting to supply to the projects.',
        a2: 'Sustainable energy and industrial projects in all phases (wind farms, solar parks, hydrogen facilities, battery factories, green steel projects, and many more)',
        a3: 'It only takes a few minutes, register your company and become visible to project owners today.',
        a4: 'Medla\'s main business model lies with municipalities and project owners.',
        a5: 'Small businesses will always be able to use Medla for free.',
        a7: 'Medla is run by Peer Digital Sweden AB. The company was founded by former project managers and developers from Umeå University.',
        a8: 'If you want to report a company on Medla, please contact us at '
      },
      email: {
        support: 'support@medla.app'
      }
    },
    help: {
      title: 'Hello!',
      description: 'How can we help you?',
      content: {
        text3: 'Please contact us via email if you need support or have questions.',
        contactButton: 'Contact support'
      }
    },
    connectProject: {
      hero: {
        title: 'Project planning and local development in harmony.',
        subtitle: 'Anchor projects, support local business mediation, and strengthen your local contribution.',
        cta: 'Contact us'
      },
      howItWorks: {
        title: 'How it works',
        steps: {
          anchor: {
            title: '1. Anchor',
            description: 'Local companies can follow a project and see what business opportunities an approved project entails.'
          },
          mediate: {
            title: '2. Mediate',
            description: 'Make it easy for project contractors to find and choose local suppliers of goods and services.'
          },
          measure: {
            title: '3. Measure results',
            description: 'See what jobs and local business your project contributes to and share the results with your stakeholders.'
          }
        }
      }
    },

    Footer: {
      copyright: '© {{year}} Peer Digital Sweden AB',
      sitemap: 'Sitemap',
      terms: 'Terms of Service',
      privacy: 'Privacy Policy'
    },

    sitemap: {
      title: 'Sitemap',
      mainPages: 'Main Pages',
      projects: 'Projects',
      companies: 'Companies',
      loading: 'Loading...'
    },
    about: {
      title: 'About Medla',
      mission: 'Medla\'s goal is to unlock local forces that accelerate the transition to fossil-free societies.',
      what: {
        title: 'What is Medla?',
        description: 'Medla is a digital service for local job matching in green industry projects. With Medla, companies and sole proprietorships can easily showcase their operations, find relevant job opportunities, and initiate business relationships with actors in the energy sector. For project owners, Medla makes it possible to reach out to local businesses and streamline planning, construction, and maintenance of their projects.'
      },
      background: {
        title: 'Background',
        description: 'Medla is based on research in informatics, digitalization and rural development, as well as user pilots in the wind power industry. Medla and associated services are provided and developed by Peer Digital Sweden AB together with a reference group of experts in Swedish business and green industry.'
      },
      goals: {
        title: 'Our Goals',
        goal1: 'Facilitate the expansion of fossil-free industry in Sweden.',
        goal2: 'Make projects accessible so that local companies and communities can benefit from investments in their vicinity.',
        goal3: 'Strengthen anchoring between project actors and local stakeholders.'
      },
      contact: {
        title: 'Contact',
        description: 'If you have questions or concerns, you can reach us at info@medla.app.'
      },
      message: {
        title: 'A message from Medla\'s initiators',
        impact: 'Local anchoring and collaboration are important pieces for successful industrial projects. Jobs contribute to this, and thanks to your projects, more of them can be created locally (up to 200% more than today) if we work together — with all actors in the chain.',
        conclusion: 'That\'s why we built Medla — to help you elevate and strengthen the social benefits that your industrial projects unlock, and streamline the work — from consultation to commissioning and on to the next project.',
        future: 'Since 2019, we have built digital solutions that have helped Sweden\'s largest project developers and over 700 local businesses build better industries and stronger local communities — and this is just the beginning. We hope you want to join us on the journey.'
      },
      challenges: {
        title: 'The challenge for many project developers is to:',
        challenge1: 'Have time to build relationships and collaborations with all actors (the municipality, business community, construction contractors, interest organizations) that can facilitate the choice of local suppliers and increase the social benefits of the project.',
        challenge2: 'Demonstrate the benefits created locally and regionally in connection with a project establishment.'
      }
    },
    terms: {
      title: 'Terms of Service',
    },
    privacy: {
      title: 'Privacy Policy',
    },
    projects: {
      velinga: {
        title: 'Welcome to Velinga',
        description: 'In early 2024, Vattenfall began construction of Velinga wind farm with 12 wind turbines with a total height of 180 meters. The project area is located in Tidaholm municipality in Västra Götaland County.',
        projectDescription: 'In early 2024, Vattenfall began construction of Velinga wind farm with 12 wind turbines with a total height of 180 meters. The project area is located in Tidaholm municipality in Västra Götaland County. In January 2024, a site office was established and work continues with infrastructure such as roads, foundations, assembly areas, and cable laying. During summer 2025, transport and assembly of the wind turbines will begin. The park is expected to be operational in early 2026.',
        projectStatus: 'Approved',
        projectMapTooltip: 'Project - Velinga',
        location: 'Tidaholm'
      },
      tomasliden: {
        title: 'Welcome to Tomasliden',
        description: 'wpd plans to construct 10 wind turbines with 200m total height at Tomasliden. The project area is located in Norsjö municipality, approximately 10 km west of Bastuträsk and 20 km southeast of Norsjö community.',
        projectDescription: 'wpd plans to construct 10 wind turbines with 200m total height at Tomasliden. The project area is located in Norsjö municipality, approximately 10 km west of Bastuträsk and 20 km southeast of Norsjö community. Reinforcement and construction of access roads and roads within the wind farm is expected to start in early November 2022. Continued construction of roads, crane plans and establishment of foundations and cables is planned to start in spring 2023. Installation of wind turbines is planned for summer 2024.',
        projectStatus: 'Under construction',
        projectMapTooltip: 'Project - Tomasliden',
        location: 'Norsjö'
      }
    }
  },
  cookieBanner: {
    message: 'We use cookies to improve your experience and to improve our service.',
    accept: 'Accept',
    learnMore: 'Learn more'
  },
  cookiePolicy: {
    title: 'Cookie Policy',
    description: 'We use the following cookies and local storage:',
    types: {
      essential: {
        title: 'Essential',
        description: 'To keep you logged in'
      },
      analytics: {
        title: 'Analytics',
        description: 'To count how many times profiles and their contact information are viewed'
      }
    },
    currentStatus: 'Current status',
    statusAccepted: 'You have accepted cookies',
    statusDeclined: 'You have not accepted cookies',
    giveConsent: 'Accept cookies',
    withdrawConsent: 'Withdraw consent'
  },
  meta: {
    defaultDescription: 'Find local forces | Medla',
    pages: {
      search: {
        title: 'Search',
        description: 'Search among local forces on Medla'
      },
      home: {
        title: 'Find Local forces',
        description: 'Find and collaborate with local forces in renewable energy'
      },
      profile: {
        defaultTitle: 'Company Profile',
        defaultDescription: 'View company profile on Medla'
      },
      about: {
        title: 'About Medla',
        description: 'Medla\'s goal is to unlock local forces that accelerate the transition to fossil-free societies.'
      },
      adminLogin: {
        title: 'Admin Login',
        description: 'Log in to Medla admin'
      },
      connectProject: {
        title: 'Connect Project',
        description: 'Project planning and local development in harmony'
      },
      cookiePolicy: {
        title: 'Cookie Policy',
        description: 'Information about how Medla uses cookies'
      },
      faq: {
        title: 'Frequently Asked Questions',
        description: 'Answers to common questions about Medla'
      },
      help: {
        title: 'Help',
        description: 'Get help with Medla'
      }
    }
  },
  footer: {
    sitemap: 'Sitemap',
    terms: 'Terms of Service',
    privacy: 'Privacy Policy',
    copyright: '© {{year}} Peer Digital Sweden AB',
    links: {
      connectProject: 'Connect Project',
      about: 'About Medla',
      faq: 'FAQ',
      help: 'Help'
    }
  },
  components: {

    ProfileEditForm: {
        sections: {
          companyDetails: 'Company Details',
          about: 'About this Company',
          services: 'Services'
        },
        fields: {
          image: {
            label: 'Company Image'
          },
          company: {
            name: 'Company Name',
            description: 'Company Description',
            address: 'Address',
            orgNumber: 'Organization Number'
          },
          contact: {
            person: 'Contact Person',
            phone: 'Phone Number',
            email: 'Email Address'
          },
          categories: {
            label: 'Categories',
            options: {
              transport: 'Transport',
              other: 'Other'
            }
          },
          services: {
            title: 'Service {{number}} - Title',
            description: 'Service {{number}} - Description',
            placeholders: {
              title: 'Enter service name',
              description: 'Describe the service...'
            }
          }
        },
        actions: {
          addService: 'Add Service',
          removeService: 'Remove Service {{number}}'
        },
        validation: {
          serviceRequired: 'Both title and description are required for each service',
          confirmDelete: 'Are you sure you want to remove this service?'
        },
        status: {
          loading: 'Loading...',
          error: 'An error occurred'
        }
      },
  
    CategorySection: {
      title: 'Categories',
      noCategories: 'No categories specified.',
      selectPlaceholder: 'Select categories...',
      examples: 'Examples:',
      categories: {
        bygg_och_konstruktion: {
          label: 'Construction & Building',
          examples: 'Construction, Concrete, Building, Blasting, Welding and metal, Project planning, Manufacturing, Forest machine services'
        },
        boende: {
          label: 'Accommodation',
          examples: 'Hotel, Hostel, Cabins, Camping, Apartments, Bed & Breakfast'
        },
        restaurang_och_catering: {
          label: 'Restaurant & Catering',
          examples: 'Restaurant, Catering, Cooking, Serving'
        },
        teknik_och_underhall: {
          label: 'Technology & Maintenance',
          examples: 'Electrical, alarm and fiber, IT and telecom, Machine repair, Operations and Maintenance'
        },
        transport_och_fordon: {
          label: 'Transport & Vehicles',
          examples: 'Vehicles and tires, Transport'
        },
        affarstjanster_och_support: {
          label: 'Business Services & Support',
          examples: 'Staffing, Education, Media and PR, Services, Conferences'
        }
      }
    },
    PopularBranches: {
      title: 'Popular Categories',
      actions: {
        seeAll: 'See all categories'
      },
      branches: {
        it: 'IT',
        healthcare: 'Healthcare',
        education: 'Education'
      }
    },
    ProjectHeader: {
      defaults: {
        coordinates: {
          lat: 64.914148,
          lng: 19.483077
        }
      },
      errors: {
        noCoordinates: 'No valid coordinates available, using default values'
      },
      map: {
        zoom: 9.9
      },
      api: {
        fetchError: 'Could not fetch companies'
      }
    },
    Header: {
        search: {
          placeholder: 'Describe what you\'re looking for',
          mobilePlaceholder: 'Describe what you\'re looking for',
          ariaLabel: {
            open: 'Open search',
            close: 'Close search'
          },
          ids: {
            searchInput: 'search-input',
            mobileSearchInput: 'mobile-search-input'
          },
          placeholders: {
            accommodation: "Cabin for 3 people with sauna",
            restaurants: "Photographer with drone access",
            services: "Electrician for barrack installation",
            events: "Conference organizer for large groups",
            professionals: "Contractor for concrete foundation"
          }
        },
        auth: {
          login: 'Log in',
          register: 'Register',
          logout: 'Log out',
          profile: 'Go to my profile',
          profileInfo: 'Profile information',
          ids: {
            loginButton: 'login-button',
            registerButton: 'register-button'
          }
        },
        modal: {
          loginTitle: 'Log in',
          registerTitle: 'Register'
        },
        accessibility: {
          logo: 'Medla Logo',
          profileImage: 'Profile image'
        }
      },

      
    ProjectInfo: {
      title: 'About the project',
      defaults: {
        name: 'Project name missing',
        description: 'Project description missing'
      },
      readMore: 'Read more'
    },
    LocationMap: {
      noLocation: 'No address information available',
      defaultLocation: {
        city: 'Stockholm',
        country: 'Sweden'
      }
    },
    OffersDisplay: {
      title: 'Services',
      actions: {
        addOffer: 'Add service',
        removeOffer: 'Remove service'
      },
      messages: {
        noOffers: 'No services available.',
        confirmDelete: 'Are you sure you want to remove this service?'
      }
    },
    ProfileHeader: {
      title: {
        default: 'New user'
      },
      contact: {
        default: 'Contact person not specified'
      },
      actions: {
        edit: 'Edit profile',
        cancel: 'Cancel editing'
      },
      imageAlt: 'Profile image'
    },
    CompanyList: {
        title: 'Local Companies',
        actions: {
          export: 'Export company list',
          showMore: 'Show more',
          showLess: 'Show less'
        },
        export: {
          columns: {
            name: 'Name',
            title: 'Title',
            email: 'Email',
            phone: 'Phone',
            address: 'Address',
            category: 'Category',
            offerHeading: 'Service heading',
            offer: 'Service'
          },
          filename: 'companies.xlsx',
          sheetName: 'Companies'
        }
      },
    CompanyCard: {
      defaults: {
        title: 'Unnamed company',
        address: 'Not specified',
      },
      accessibility: {
        profileImage: 'Company logo',
        placeholderImage: 'Company icon'
      },
      categories: {
        bygg_och_konstruktion: 'Construction & Building',
        teknik_och_underhall: 'Technology & Maintenance',
        transport_och_fordon: 'Transport & Vehicles',
        affarstjanster_och_support: 'Business Services & Support',
        restaurang_och_catering: 'Restaurant & Catering',
        boende: 'Accommodation'
      },

      
      subcategories: {
        anlaggning: 'Construction',
        betong: 'Concrete',
        bygg: 'Building',
        sprangning: 'Blasting',
        svets_och_metall: 'Welding and metal',
        projektering: 'Project planning',
        tillverkning: 'Manufacturing',
        skogsmaskintjanster: 'Forest machine services',
        el_larm_fiber: 'Electrical, alarm and fiber',
        it_telecom: 'IT and telecom',
        maskinreparation: 'Machine repair',
        drift_underhall: 'Operations and Maintenance',
        fordon_dack: 'Vehicles and tires',
        transport: 'Transport',
        bemanning: 'Staffing',
        utbildning: 'Education',
        media_pr: 'Media and PR',
        servicetjanster: 'Service',
        kost_logi: 'Food and accommodation',
        ovrigt: 'Other'
      }
    }
  }
}

