import React from 'react';
import { Helmet } from 'react-helmet';

const ProfileMetaTags = ({ userProfile, profileImageUrl }) => {
  if (!userProfile) return null;

  const companyName = userProfile.Title || userProfile.DisplayName || '';
  const description = userProfile.PublicData_y?.description || '';
  const pageUrl = `https://www.medla.app/profile/${userProfile.uid || userProfile.AuthorId}`;

  return (
    <Helmet>
      <title>{`${companyName} | Medla`}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={`${companyName} | Medla`} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={profileImageUrl || '/og-image.jpg'} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={profileImageUrl || '/og-image.jpg'} />
    </Helmet>
  );
};

export default ProfileMetaTags; 