import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { changeLanguage, getCurrentLanguage } from '../config/languageConfig';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const location = useLocation();
  const [language, setLanguage] = useState(getCurrentLanguage());

  useEffect(() => {
    // Set language based on URL
    const newLang = location.pathname.startsWith('/en') ? 'en' : 'sv';
    if (newLang !== language) {
      setLanguage(newLang);
      changeLanguage(newLang);
    }
  }, [location, language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};