import React, { useEffect, useState } from 'react';
import { generateSitemap } from '../../utils/sitemapGenerator';

const Sitemap = () => {
  const [sitemap, setSitemap] = useState('');

  useEffect(() => {
    const fetchSitemap = async () => {
      const xml = await generateSitemap();
      if (xml) {
        setSitemap(xml);
      }
    };
    fetchSitemap();
  }, []);

  if (!sitemap) return null;

  return (
    <pre style={{ display: 'none' }}>
      {sitemap}
    </pre>
  );
};

export default Sitemap; 