import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import './ProjectPage.css';
import ProjectHeader from '../../components/ProjectHeader/ProjectHeader';
import ProjectInfo from '../../components/ProjectInfo/ProjectInfo';
import ProjectPageSkeleton from '../../components/SkeletonLoaders/ProjectPageSkeleton/ProjectPageSkeleton';

const ProjectPage = ({ config, projectId }) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!config) {
    return <ProjectPageSkeleton />;
  }

  const projectTranslations = {
    title: t(`pages.projects.${projectId}.title`),
    description: t(`pages.projects.${projectId}.description`),
    projectDescription: t(`pages.projects.${projectId}.projectDescription`),
    location: t(`pages.projects.${projectId}.location`),
    status: t(`pages.projects.${projectId}.projectStatus`),
  };

  console.log('Project Translations:', projectTranslations);

  const getProfileLink = (profileId) => {
    const languagePrefix = location.pathname.startsWith('/en') ? '/en' : '';
    return `${languagePrefix}/profile/${profileId}`;
  };

  return (
    <div className="project-page" data-testid="project-page">
      <ProjectHeader 
        config={config} 
        title={projectTranslations.title}
        description={projectTranslations.description}
        data-testid="project-header"
        getProfileLink={getProfileLink}
      />
      <ProjectInfo 
        title={projectTranslations.title}
        description={projectTranslations.description}
        projectDescription={projectTranslations.projectDescription}
        banner={config.headerImage}
        data-testid="project-info"
        projectLink={config.projectLink}
        staticMap={true}
        location={projectTranslations.location}
        status={projectTranslations.status}
        getProfileLink={getProfileLink}
      />
    </div>
  );
};

export default ProjectPage;
