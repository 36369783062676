import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import CookieBanner from '../../components/CookieBanner/CookieBanner';
import SearchPageMapComponent from '../../components/SearchPageMapComponent/SearchPageMapComponent';
import CategorySection from '../../components/ProfilePage/CategorySection';
import OffersDisplay from '../../components/ProfilePage/OffersDisplay';
import PopularBranches from '../../components/PopularBranches/PopularBranches';
import { Modal, Button } from 'react-bootstrap';

const StylePage = () => {
  const [activeSection, setActiveSection] = useState('typography');
  const [showStandardModal, setShowStandardModal] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [showScrollableModal, setShowScrollableModal] = useState(false);

  // Mock data for components
  const mockCompany = {
    data: {
      Title: 'Example Company',
      AuthorId: '123',
      State_y: 'published',
      PublicData_y: {
        location: {
          address: '123 Example St',
          latitude: 59.3293,
          longitude: 18.0686
        },
        category: ['bygg_och_konstruktion'],
        description: 'An example company description'
      }
    }
  };

  const mockOffers = [
    { heading: 'Service 1', content: 'Service 1 description' },
    { heading: 'Service 2', content: 'Service 2 description' }
  ];

  const mockBranches = ['it', 'healthcare', 'education'];

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // Add toast notification
  };

  const sections = [
    { id: 'typography', label: 'Typography' },
    { id: 'colors', label: 'Colors' },
    { id: 'buttons', label: 'Buttons' },
    { id: 'forms', label: 'Form Elements' },
    { id: 'components', label: 'Components' },
    { id: 'responsive', label: 'Responsive' }
  ];

  const handleNavClick = (e, sectionId) => {
    e.preventDefault(); // Prevent default anchor behavior
    setActiveSection(sectionId);
    const element = document.getElementById(sectionId);
    if (element) {
      // Add offset for header height
      const headerOffset = 100; // Adjust this value based on your header height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const colorPalette = [
    { name: 'Primary', value: '#0D243A', variable: '--primary-color', description: 'Main brand color' },
    { name: 'Secondary', value: '#EFF9F6', variable: '--secondary-color', description: 'Secondary brand color' },
    { name: 'Accent', value: '#295376', variable: '--accent-color', description: 'Accent color for highlights' },
    { name: 'Success', value: '#20ba82', variable: '--success-color', description: 'Success states' },
    { name: 'Warning', value: '#e9ac32', variable: '--warning-color', description: 'Warning states' },
    { name: 'Error', value: '#d93e3e', variable: '--error-color', description: 'Error states' },
    { name: 'Text', value: '#2D3436', variable: '--text-color', description: 'Main text color' },
    { name: 'Background', value: '#FCFCFC', variable: '--background-color', description: 'Background color' }
  ];

  const mockCompanies = [
    {
      data: {
        Title: 'Stockholm Office',
        AuthorId: '123',
        PublicData_y: {
          location: {
            latitude: 59.3293,
            longitude: 18.0686,
            address: 'Stockholm, Sweden'
          }
        }
      }
    },
    {
      data: {
        Title: 'Gothenburg Office',
        AuthorId: '456',
        PublicData_y: {
          location: {
            latitude: 57.7089,
            longitude: 11.9746,
            address: 'Gothenburg, Sweden'
          }
        }
      }
    },
    {
      data: {
        Title: 'Malmö Office',
        AuthorId: '789',
        PublicData_y: {
          location: {
            latitude: 55.6049,
            longitude: 13.0038,
            address: 'Malmö, Sweden'
          }
        }
      }
    }
  ];

  return (
    <div className="style-page">
      <div className="container-fluid">
        <div className="row">
          <nav className="col-md-3 d-none d-md-block sidebar">
            <div className="position-sticky pt-3">
              <h4 className="sidebar-heading">Design System</h4>
              <ul className="nav flex-column">
                {sections.map(section => (
                  <li className="nav-item" key={section.id}>
                    <a 
                      className={`nav-link ${activeSection === section.id ? 'active' : ''}`}
                      onClick={(e) => handleNavClick(e, section.id)}
                      href={`#${section.id}`}
                      role="button"
                      tabIndex={0}
                    >
                      {section.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>

          <main className="col-md-9 ms-sm-auto px-md-4">
            <div className="style-content">
              <section id="typography" className="style-section">
                <h2>Typography</h2>
                <div className="style-examples">
                  <h1>Heading 1</h1>
                  <h2>Heading 2</h2>
                  <h3>Heading 3</h3>
                  <h4>Heading 4</h4>
                  <h5>Heading 5</h5>
                  <h6>Heading 6</h6>
                  <p>Regular paragraph text</p>
                  <p className="text-small">Small text</p>
                </div>
              </section>

              <section id="colors" className="style-section">
                <h2>Colors</h2>
                <div className="color-grid">
                  {colorPalette.map((color) => (
                    <div key={color.name} className="color-item">
                      <div 
                        className="color-swatch" 
                        style={{ backgroundColor: color.value }}
                      />
                      <div className="color-info">
                        <h4>{color.name}</h4>
                        <code>{color.variable}</code>
                        <p>{color.value}</p>
                        <small>{color.description}</small>
                      </div>
                    </div>
                  ))}
                </div>
              </section>

              <section id="buttons" className="style-section">
                <h2>Buttons</h2>
                <div className="style-examples">
                  <h3>Standard Buttons</h3>
                  <div className="button-group">
                    <button className="btn btn-primary">Primary Button</button>
                    <button className="btn btn-secondary">Secondary Button</button>
                    <button className="btn btn-warning">Warning Button</button>
                    <button className="btn" disabled>Disabled Button</button>
                  </div>

                  <h3 className="mt-4">Button Sizes</h3>
                  <div className="button-group">
                    <button className="btn btn-primary btn-sm">Small</button>
                    <button className="btn btn-primary">Medium</button>
                    <button className="btn btn-primary btn-lg">Large</button>
                  </div>

                  <h3 className="mt-4">Button States</h3>
                  <div className="button-group">
                    <button className="btn btn-primary">Normal</button>
                    <button className="btn btn-primary active">Active</button>
                    <button className="btn btn-primary" disabled>Disabled</button>
                  </div>

                  <h3 className="mt-4">Icon Buttons</h3>
                  <div className="button-group">
                    <button className="btn btn-icon">
                      <i className="fas fa-search"></i>
                    </button>
                    <button className="btn btn-primary btn-icon">
                      <i className="fas fa-plus"></i>
                      Add New
                    </button>
                  </div>
                </div>
              </section>

              <section id="forms" className="style-section">
                <h2>Form Elements</h2>
                <div className="style-examples">
                  <div className="form-group">
                    <label>Text Input</label>
                    <input type="text" className="form-control" placeholder="Enter text" />
                  </div>

                  <div className="form-group">
                    <label>Select</label>
                    <select className="form-control">
                      <option>Select an option</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Switch</label>
                    <div className="form-switch">
                      <input type="checkbox" id="switch1" className="switch-input" />
                      <label htmlFor="switch1" className="switch-label">Toggle switch</label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Checkbox</label>
                    <div className="form-check">
                      <input type="checkbox" id="check1" className="form-check-input" />
                      <label htmlFor="check1" className="form-check-label">Checkbox option</label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Radio</label>
                    <div className="form-check">
                      <input type="radio" name="radio1" id="radio1" className="form-check-input" />
                      <label htmlFor="radio1" className="form-check-label">Radio option 1</label>
                    </div>
                    <div className="form-check">
                      <input type="radio" name="radio1" id="radio2" className="form-check-input" />
                      <label htmlFor="radio2" className="form-check-label">Radio option 2</label>
                    </div>
                  </div>
                </div>
              </section>

              <section id="components" className="style-section">
                <h2>Components</h2>
                
                <h3>Company Card</h3>
                <div className="style-examples">
                  <CompanyCard 
                    company={mockCompany}
                    handleCardClick={() => {}}
                  />
                </div>

                <h3>Map</h3>
                <div className="style-examples">
                  <SearchPageMapComponent 
                    companies={[mockCompany]}
                    center={[18.0686, 59.3293]}
                    zoom={11}
                    onMarkerClick={() => {}}
                    staticMap={false}
                  />
                  <div className="map-description">
                    Interactive map with navigation controls and markers
                  </div>
                </div>

                <h3>Modal Dialogs</h3>
                <div className="style-examples">
                  {/* Standard Modal */}
                  <div className="button-group mb-4">
                    <Button onClick={() => setShowStandardModal(true)}>
                      Standard Modal
                    </Button>
                    <Button onClick={() => setShowMobileModal(true)}>
                      Mobile-Optimized Modal
                    </Button>
                    <Button onClick={() => setShowScrollableModal(true)}>
                      Scrollable Modal
                    </Button>
                  </div>

                  {/* Standard Modal */}
                  <Modal 
                    show={showStandardModal} 
                    onHide={() => setShowStandardModal(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Standard Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>This is a standard modal dialog with header, body, and footer sections.</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowStandardModal(false)}>
                        Close
                      </Button>
                      <Button variant="primary">
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Mobile-Optimized Modal */}
                  <Modal 
                    show={showMobileModal} 
                    onHide={() => setShowMobileModal(false)}
                    className="mobile-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Mobile Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>This modal is optimized for mobile devices with bottom sheet behavior on small screens.</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowMobileModal(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Scrollable Modal */}
                  <Modal 
                    show={showScrollableModal} 
                    onHide={() => setShowScrollableModal(false)}
                    scrollable
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Scrollable Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div style={{ height: '600px' }}>
                        <p>This modal has scrollable content.</p>
                        <p>Lorem ipsum dolor sit amet... {/* Add more content */}</p>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowScrollableModal(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </section>

              <section id="responsive" className="style-section">
                <h2>Responsive Behavior</h2>
                <div className="style-examples">
                  <div className="screen-size-indicator d-block d-sm-none">XS</div>
                  <div className="screen-size-indicator d-none d-sm-block d-md-none">SM</div>
                  <div className="screen-size-indicator d-none d-md-block d-lg-none">MD</div>
                  <div className="screen-size-indicator d-none d-lg-block">LG</div>
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default StylePage;
