export const privacyPolicy = `
Sekretesspolicy för Medla
Senast uppdaterad: okt 26, 2021

Tack för att du använder Medla! Denna integritetspolicy beskriver hur din personliga information samlas in, används och delas när du besöker eller gör ett köp från www.medla.app (”Sajten”).

Personlig data vi samlar in
När du besöker webbplatsen samlar vi automatiskt in viss information om din enhet, inklusive information om din webbläsare, IP-adress, tidszon och några av de cookies som är installerade på din enhet. När du surfar på webbplatsen samlar vi dessutom in information om enskilda webbsidor eller produkter som du tittar på, vilka webbplatser eller söktermer som hänvisade dig till webbplatsen och information om hur du interagerar med webbplatsen och andra användare på webbplatsen. Vi hänvisar till denna automatiskt insamlade information som "Enhetsinformation". Vi samlar in enhetsinformation med hjälp av följande tekniker:

- "Cookies" är datafiler som placeras på din enhet eller dator och innehåller ofta en anonym unik identifierare. Mer information om cookies och hur du inaktiverar cookies finns på http://www.allaboutcookies.org. - "Log files" spårar åtgärder som sker på webbplatsen och samlar in data inklusive din IP -adress, webbläsartyp, Internetleverantör, hänvisnings-/utgångssidor och datum-/tidsstämplar. - "Web beacons", "tags" och "pixels" är elektroniska filer som används för att spela in information om hur du surfar på webbplatsen.

När du gör ett köp eller försöker göra ett köp via webbplatsen, samlar vi in ​​viss information från dig, inklusive ditt namn, faktureringsadress, leveransadress, betalningsinformation (inklusive kreditkortsnummer och bankkontouppgifter), e-postadress och telefonnummer. Vi hänvisar till denna information som "Beställningsinformation".

När vi pratar om "personlig information" i denna sekretesspolicy, talar vi både om enhetsinformation och orderinformation.

HUR ANVÄNDER VI ANVÄNDARDATA?
Vi använder beställningsinformationen som vi samlar in i allmänhet för att utföra alla beställningar som görs via webbplatsen (inklusive bearbetning av din betalningsinformation, förse dig med fakturor och/eller orderbekräftelser). Dessutom använder vi denna beställningsinformation för att: Kommunicera med dig; Kontrollera våra beställningar för potentiell risk eller bedrägeri; och Om du är i linje med de preferenser du har delat med oss, ge dig information eller reklam som rör våra produkter eller tjänster. Vi använder enhetsinformationen som vi samlar in för att hjälpa oss att se efter potentiella risker och bedrägerier (särskilt din IP -adress) och mer generellt för att förbättra och optimera vår webbplats (till exempel genom att generera analyser om hur våra kunder surfar och interagerar med webbplatsen, och för att bedöma framgången för våra marknadsförings- och reklamkampanjer).

DELA DIN ANVÄNDARINFORMATION
Vi delar din personliga information med tredje part för att ta vara på din personliga information, enligt beskrivningen ovan. Vi använder också Google Analytics för att hjälpa oss att förstå hur våra kunder använder webbplatsen-du kan läsa mer om hur Google använder din personliga Information här: https://www.google.com/intl/en/policies/privacy/. Du kan också välja bort Google Analytics här: https://tools.google.com/dlpage/gaoptout.

Vidare, så delar vi din personliga information med vår Referensgrupp, referensgruppen består av representanter från energibranschen, vi delar din information för att förbättra tjänsten, visa tjänstens effekt och för att påvisa värdet av tjänsten för referensgruppen. Informationen kan också användas av medlemmar i referensgruppen i syfte att göra informationsutskick till användare av Medla ( "Sajten").

Dessutom, så delar vi också din information med våra partners (projektägare), vi delar dina kontaktuppgifter (mailadresser och telefonnummer) samt organisationsnummer till våra partners. Syftet med delningen av data till våra partners är att ge dem sätt att hitta och kontakta användare i syftet att visa upp relevanta jobbmöjligheter. Vidare, så syns användares kontaktuppgifter publikt på tjänsten (Medla).

Slutligen kan vi också dela din personliga information för att följa tillämpliga lagar och förordningar, för att svara på en stämning, sökorder eller annan laglig begäran om information vi får, eller för att på annat sätt skydda våra rättigheter.

Som beskrivits ovan använder vi din personliga information för att förse dig med riktade annonser eller marknadsföringskommunikation som vi tror kan vara av intresse för dig. För mer information om hur riktad annonsering fungerar, kan du besöka Network Advertising Initiative’s (”NAI”) utbildningssida på http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.

Du kan välja bort riktad reklam genom att ändra dina inställningar hos följande tjänsteleverantörer (länkar tillhandahålls endast för enkelhets skull och kan inte garanteras vara uppdaterade):
Facebook - https://www.facebook.com/settings/?tab=ads

Google - https://www.google.com/settings/ads/anonymous

Dessutom kan du välja bort några av dessa tjänster genom att besöka Digital Advertising Alliances borttagningsportal på: http://optout.aboutads.info/.

DO NOT TRACK
Observera att vi inte ändrar vår webbplats datainsamling och användning när vi ser en Do not track -signal från din webbläsare. Om du är bosatt i Europa har du rätt att få tillgång till personlig information vi har om dig och att begära att dina personuppgifter korrigeras, uppdaterad eller raderas. Om du vill utöva denna rättighet, vänligen kontakta oss via kontaktinformationen nedan. Dessutom, om du är bosatt i Europa noterar vi att vi behandlar din information för att uppfylla avtal vi kan ha med dig (till exempel om du gör en beställning via webbplatsen), eller på annat sätt för att fullfölja våra legitima affärsintressen som anges ovan. Dessutom, Observera att din information kommer att överföras utanför Europa, inklusive till Kanada och USA.

DATABEHÅLLNING
När du lägger en beställning via webbplatsen kommer vi att behålla din beställningsinformation för i våra register om inte och tills du ber oss att radera denna information.

MINDERÅRIGA
Webbplatsen är inte avsedd för personer under 18 år.

ÄNDRINGAR
Vi kan komma att uppdatera denna sekretesspolicy då och då för att återspegla exempelvis ändringar i våra metoder eller av andra operativa, juridiska eller regulatoriska skäl.

KONTAKTA OSS
För mer information om våra sekretessrutiner, om du har frågor eller om du vill göra ett klagomål, vänligen kontakta oss via e-post på info@peerdigital.se.`;