import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MedlaLogo } from '../../assets/medla_logo.svg';
import { FaGlobe } from 'react-icons/fa';
import { useLanguage } from '../../context/LanguageContext';
import './Footer.css';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useLanguage();
  
  const noFooterRoutes = ['/search', '/some-other-page'];

  if (noFooterRoutes.includes(location.pathname)) {
    return null;
  }

  const handleLanguageChange = (newLang) => {
    const currentPath = location.pathname;
    const currentSearch = location.search;
    
    localStorage.setItem('i18nextLng', newLang);
    
    if (newLang === 'en') {
      if (!currentPath.startsWith('/en')) {
        const newPath = currentPath === '/' ? '/en' : `/en${currentPath}`;
        navigate(newPath + currentSearch);
      }
    } else {
      if (currentPath.startsWith('/en')) {
        const newPath = currentPath.replace(/^\/en/, '');
        navigate(newPath + currentSearch);
      }
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Column 1 - Logo, Description, and Language Selector */}
        <div className="footer-column">
          <div className="footer-logo">
            <MedlaLogo />
          </div>
          <p className="footer-copyright">
            {t('footer.copyright', { year: new Date().getFullYear() })}
          </p>
          <div className="footer-language-selector">
            <FaGlobe className="globe-icon" />
            <select 
              value={language} 
              onChange={(e) => handleLanguageChange(e.target.value)}
              className="footer-language-select"
            >
              <option value="sv">Svenska</option>
              <option value="en">English</option>
            </select>
          </div>
      
        </div>

        {/* Column 2 - About */}
        <div className="footer-column">
          <Link to="/connect-project" className="footer-link">{t('footer.links.connectProject')}</Link>
          <Link to="/about" className="footer-link">{t('footer.links.about')}</Link>
        </div>

        {/* Column 3 - Help */}
        <div className="footer-column">
          <Link to="/faq" className="footer-link">{t('footer.links.faq')}</Link>
          <Link to="/help" className="footer-link">{t('footer.links.help')}</Link>
        </div>  

        {/* Column 4 - Legal */}
        <div className="footer-column">
          <Link to="/sitemap" className="footer-link">{t('footer.sitemap')}</Link>
          <Link to="/terms-of-service" className="footer-link">{t('footer.terms')}</Link>
          <Link to="/privacy-policy" className="footer-link">{t('footer.privacy')}</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
