import React from 'react';
import ProjectHeaderSkeleton from './ProjectHeaderSkeleton';
import './ProjectPageSkeleton.css';

const ProjectPageSkeleton = () => {
  return (
    <div className="project-page">
      <ProjectHeaderSkeleton />
      
      {/* Project Info Section */}
      <div className="project-info-skeleton">
        <div className="skeleton-section">
          <div className="skeleton-section-title skeleton-pulse"></div>
          <div className="skeleton-text-block skeleton-pulse"></div>
          <div className="skeleton-text-block skeleton-pulse"></div>
        </div>

        <div className="skeleton-section">
          <div className="skeleton-section-title skeleton-pulse"></div>
          <div className="skeleton-status skeleton-pulse"></div>
        </div>

        <div className="skeleton-section">
          <div className="skeleton-section-title skeleton-pulse"></div>
          <div className="skeleton-location-text skeleton-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPageSkeleton;