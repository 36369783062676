import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProjectPage from '../../pages/ProjectPage/ProjectPage';
import { velingaConfig, tomaslidenConfig } from '../../config/projectConfig';

const ProjectPageWrapper = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();

  const getProjectConfig = (id) => {
    switch (id) {
      case 'velinga':
        return velingaConfig;
      case 'tomasliden':
        return tomaslidenConfig;
      default:
        return null;
    }
  };

  const config = getProjectConfig(projectId);

  if (!config) {
    return <div>{t('pages.project.notFound', 'Project not found')}</div>;
  }

  return <ProjectPage config={config} projectId={projectId} />;
};

export default ProjectPageWrapper;

