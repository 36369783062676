import React from 'react';
import './SkeletonCard.css';

const SkeletonCard = () => (
  <div className="skeleton-card" data-testid="skeleton-card">
    <div className="card-body">
      <div className="card-header">
        <div className="skeleton-image pulse" />
        <div className="skeleton-title pulse" />
      </div>
      <div className="skeleton-address pulse" />
      <div className="skeleton-description pulse" />
      <div className="skeleton-categories">
        <div className="skeleton-tag pulse" />
        <div className="skeleton-tag pulse" />
      </div>
    </div>
  </div>
);

export default SkeletonCard; 