// src/routes.js
import React from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import FinishSignUp from "./pages/FinishSignup/FinishSignUp";
import ProfilePage from './pages/ProfilePage/ProfilePage';
import SearchPage from './pages/SearchPage/SearchPage';
import ProjectPageWrapper from './components/ProjectPageWrapper/ProjectPageWrapper';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy';
import StylePage from './pages/StylePage/StylePage';
import FaqPage from "./pages/FaqPage/FaqPage";
import HelpPage from './pages/HelpPage/HelpPage';
import ConnectProjectPage from './pages/ConnectProjectPage/ConnectProjectPage';
import SitemapPage from './pages/SitemapPage/SitemapPage';
import AboutPage from './pages/AboutPage/AboutPage';
import TermsPage from './pages/TermsPage/TermsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { useEffect } from "react";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// New component to handle language routing
const LanguageRouter = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const currentLanguage = localStorage.getItem('i18nextLng') || 'sv';
    const isEnglishPath = location.pathname.startsWith('/en');
    
    // Skip if we're at root path
    if (location.pathname === '/' || location.pathname === '/en') return;
    
    // Only redirect if there's a mismatch between path and selected language
    if (currentLanguage === 'en' && !isEnglishPath) {
      navigate(`/en${location.pathname}`, { replace: true });
    } else if (currentLanguage === 'sv' && isEnglishPath) {
      navigate(location.pathname.replace('/en', ''), { replace: true });
    }
  }, [location.pathname, navigate]);

  return children;
};

const AppRoutes = () => {
  const isProd = process.env.REACT_APP_ENV === 'production';

  return (
    <LanguageRouter>
      <ScrollToTop />
      <Routes>
        {/* Default (Swedish) routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage modalType="login" />} />
        <Route path="/signup" element={<LoginPage modalType="register" />} />
        <Route path="/finishSignup" element={<FinishSignUp />} />
        <Route path="/finalize-login" element={<FinishSignUp />} />
        <Route path="/__/auth/action" element={<FinishSignUp />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/profile/:id" element={<ProfilePage />} />
        <Route path="/profile/:id/edit" element={<ProfilePage />} />
        <Route path="/projekt/:projectId" element={<ProjectPageWrapper />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/faq" element={<FaqPage />} />
        {!isProd && <Route path="/style-guide" element={<StylePage />} />}
        <Route path="/help" element={<HelpPage />} />
        <Route path="/connect-project" element={<ConnectProjectPage />} />
        <Route path="/sitemap" element={<SitemapPage />} />
        <Route path="/vanliga-fragor" element={<Navigate to="/faq" replace />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/terms-of-service" element={<TermsPage />} />
        <Route path="/terms" element={<Navigate to="/terms-of-service" replace />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        {/* English routes */}
        <Route path="/en" element={<HomePage />} />
        <Route path="/en/login" element={<LoginPage modalType="login" />} />
        <Route path="/en/signup" element={<LoginPage modalType="register" />} />
        <Route path="/en/finishSignup" element={<FinishSignUp />} />
        <Route path="/en/search" element={<SearchPage />} />
        <Route path="/en/profile/:id" element={<ProfilePage />} />
        <Route path="/en/profile/:id/edit" element={<ProfilePage />} />
        <Route path="/en/projekt/:projectId" element={<ProjectPageWrapper />} />
        <Route path="/en/cookie-policy" element={<CookiePolicy />} />
        <Route path="/en/faq" element={<FaqPage />} />
        <Route path="/en/help" element={<HelpPage />} />
        <Route path="/en/connect-project" element={<ConnectProjectPage />} />
        <Route path="/en/sitemap" element={<SitemapPage />} />
        <Route path="/en/about" element={<AboutPage />} />
        <Route path="/en/terms-of-service" element={<TermsPage />} />
        <Route path="/en/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/en/projects/:projectId" element={<ProjectPageWrapper />} />

        {/* Redirects */}
        <Route path="/anslut-projekt" element={<Navigate to="/connect-project" replace />} />
        <Route path="/velinga" element={<Navigate to="/projekt/velinga" replace />} />
        <Route path="/tomasliden" element={<Navigate to="/projekt/tomasliden" replace />} />
        <Route path="/projects/:projectId" element={<Navigate to="/projekt/:projectId" replace />} />
        <Route path="/en/*" element={<Navigate to="/en" replace />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </LanguageRouter>
  );
};

export default AppRoutes;
