// /src/authentication/Firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyD-TMHSkY7bDnBbDgEkQZxXlqWT7oSzJH0",
  authDomain: "medla-auth.firebaseapp.com",
  projectId: "medla-auth",
  storageBucket: "medla-auth.appspot.com",
  messagingSenderId: "962135691727",
  appId: "1:962135691727:web:e3acc5d334e0c7dd0fc2ba",
  measurementId: "G-L7HWZ9N9NK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Add Google Auth provider
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

const storage = getStorage(app);

export { auth, googleProvider, storage };
