import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PageMetaTags = ({ title, description, image = '/og-image.jpg', linkTitle = null }) => {
  const { t } = useTranslation();
  const currentUrl = window.location.href;
  
  // If no custom title is provided, use the default site name
  const pageTitle = title ? `${title} | Medla` : 'Medla';
  const pageDescription = description || t('meta.defaultDescription');
  // Use linkTitle if provided, otherwise use the regular title
  const ogTitle = linkTitle || title || 'Medla';

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Medla" />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content="@medla_app" />
    </Helmet>
  );
};

export default PageMetaTags; 