import React from 'react';
import { Map, Marker, NavigationControl } from 'react-map-gl';
import { useTranslation } from 'react-i18next';
import './ProfilePageMap.css';

const LocationMap = ({ 
  userProfile, 
  mapKey, 
  isProfilePage,
  className,
  dragPan = true,
  dragRotate = true,
  scrollZoom = true,
  touchZoom = true,
  touchRotate = true,
  keyboard = true,
  doubleClickZoom = true
}) => {
  const { t } = useTranslation();
  
  const DEFAULT_LAT = 59.3293;
  const DEFAULT_LNG = 18.0686;

  const coordinates = React.useMemo(() => {
    const location = userProfile?.PublicData_y?.location;
    const lat = Number(location?.latitude);
    const lng = Number(location?.longitude);
    
    if (!isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0) {
      return [lng, lat];
    }
    
    return [DEFAULT_LNG, DEFAULT_LAT];
  }, [userProfile]);

  if (isProfilePage && !userProfile?.PublicData_y?.location?.address) {
    return (
      <div className={`location-map-placeholder ${className || ''}`}>
        <p>{t('components.LocationMap.noLocation')}</p>
      </div>
    );
  }

  return (
    <div className={`location-map ${className || ''}`}>
      <Map
        key={mapKey}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        initialViewState={{
          longitude: coordinates[0],
          latitude: coordinates[1],
          zoom: 11
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        dragPan={dragPan}
        dragRotate={dragRotate}
        scrollZoom={scrollZoom}
        touchZoom={touchZoom}
        touchRotate={touchRotate}
        keyboard={keyboard}
        doubleClickZoom={doubleClickZoom}
      >
        <NavigationControl showCompass={false} />
        <Marker
          longitude={coordinates[0]}
          latitude={coordinates[1]}
        />
      </Map>
    </div>
  );
};

export default LocationMap;
