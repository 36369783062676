import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookieConsent } from '../../context/CookieConsentContext';
import './CookieBanner.css';

const CookieBanner = () => {
  const { hasConsent, giveConsent } = useCookieConsent();
  const { t } = useTranslation();
  
  if (hasConsent !== null) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <div className="cookie-message">
        <span>
          Välkommen till Medla! Vi använder{' '}
          <Link 
            to="/cookie-policy" 
            className="cookie-link"
          >
            cookies
          </Link>
          {' '}för att förbättra din upplevelse.
        </span>
      </div>
      <button 
        onClick={giveConsent} 
        className="continue-btn"
      >
        <span>Fortsätt</span>
      </button>
    </div>
  );
};

export default CookieBanner;