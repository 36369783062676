import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ProfilePageSkeleton.css';

const ProfilePageSkeleton = () => {
  return (
    <Container className="profile-container" data-testid="profile-skeleton">
      <div className="profile-header">
        <div className="profile-image-container">
          <div className="skeleton-image skeleton-pulse"></div>
        </div>
        <div className="profile-title-container">
          <div className="skeleton-title skeleton-pulse"></div>
          <div className="skeleton-company-info skeleton-pulse"></div>
        </div>
      </div>

      <Row>
        <Col md={7}>
          <div className="profile-main">
            {/* About Section */}
            <div className="about-section">
              <div className="skeleton-section-title skeleton-pulse"></div>
              <div className="skeleton-description skeleton-pulse"></div>
            </div>

            {/* Categories */}
            <div className="category-tags">
              {[1, 2, 3].map((i) => (
                <div key={i} className="skeleton-tag skeleton-pulse"></div>
              ))}
            </div>

            {/* Offers */}
            <div className="offers">
              {[1, 2, 3].map((i) => (
                <div key={i} className="skeleton-accordion skeleton-pulse"></div>
              ))}
            </div>
          </div>
        </Col>

        <Col md={5}>
          <div className="profile-sidebar">
            <div className="skeleton-map skeleton-pulse"></div>
            <div className="contact-buttons">
              <div className="skeleton-contact-btn skeleton-pulse"></div>
              <div className="skeleton-contact-btn skeleton-pulse"></div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePageSkeleton;