import React from 'react';
import './ProjectHeaderSkeleton.css';

const ProjectHeaderSkeleton = () => {
  return (
    <div className="project-header-container">
      <div className="project-header skeleton-header">
        <div className="project-header-info">
          <div className="skeleton-title skeleton-pulse"></div>
          <div className="skeleton-description skeleton-pulse"></div>
          <div className="skeleton-description skeleton-pulse" style={{ width: '80%' }}></div>
        </div>
        <div className="project-header-map">
          <div className="skeleton-map skeleton-pulse"></div>
        </div>
      </div>
      
      {/* Company List Skeleton */}
      <div className="skeleton-company-list">
        {[1, 2, 3].map((i) => (
          <div key={i} className="skeleton-company-card skeleton-pulse"></div>
        ))}
      </div>
    </div>
  );
};

export default ProjectHeaderSkeleton;