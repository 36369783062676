import axios from 'axios';

export const generateSitemap = async () => {
  try {
    // Get companies from backend
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/companies`);
    const companies = response.data;
    
    // Static pages with their priorities
    const staticPages = [
      { url: '/', priority: '1.0', changefreq: 'daily' },
      { url: '/search', priority: '0.9', changefreq: 'daily' },
      { url: '/about', priority: '0.8', changefreq: 'monthly' },
      { url: '/help', priority: '0.8', changefreq: 'monthly' },
      { url: '/faq', priority: '0.8', changefreq: 'monthly' },
      { url: '/connect-project', priority: '0.8', changefreq: 'weekly' }
    ];

    // Update project pages to use /projekt
    const projectPages = [
      { url: '/projekt/velinga', priority: '0.8', changefreq: 'weekly' },
      { url: '/projekt/tomasliden', priority: '0.8', changefreq: 'weekly' }
    ];

    const today = new Date().toISOString().split('T')[0];
    const baseUrl = process.env.NODE_ENV === 'production' ? 'https://medla.app' : 'http://localhost:3000';

    const xml = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${staticPages.map(page => `
  <url>
    <loc>${baseUrl}${page.url}</loc>
    <lastmod>${today}</lastmod>
    <changefreq>${page.changefreq}</changefreq>
    <priority>${page.priority}</priority>
  </url>`).join('')}
  
  ${projectPages.map(page => `
  <url>
    <loc>${baseUrl}${page.url}</loc>
    <lastmod>${today}</lastmod>
    <changefreq>${page.changefreq}</changefreq>
    <priority>${page.priority}</priority>
  </url>`).join('')}
  
  ${companies.map(company => `
  <url>
    <loc>${baseUrl}/profile/${company.id}</loc>
    <lastmod>${today}</lastmod>
    <changefreq>weekly</changefreq>
    <priority>0.8</priority>
  </url>`).join('')}
</urlset>`;

    return xml;
  } catch (error) {
    console.error('Error generating sitemap:', error);
    
    // Fallback to static sitemap if company fetch fails
    const baseUrl = process.env.NODE_ENV === 'production' ? 'https://medla.app' : 'http://localhost:3000';
    const staticXml = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url>
    <loc>${baseUrl}/</loc>
    <changefreq>daily</changefreq>
    <priority>1.0</priority>
  </url>
  <url>
    <loc>${baseUrl}/search</loc>
    <changefreq>daily</changefreq>
    <priority>0.9</priority>
  </url>
</urlset>`;
    
    return staticXml;
  }
};

export default generateSitemap; 