import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBuilding } from 'react-icons/fa';
import { getResizedImageUrl } from '../../utils/imageUtils';
import './CompanyCard.css';

const CompanyCard = ({ company, handleCardClick, currentUser }) => {
  const { t } = useTranslation();
  const data = company.data || company._source || {};
  
  // Check if the company is published or if it's the current user's profile
  if (data.State_y !== 'published' && (!currentUser || currentUser.uid !== data.AuthorId)) {
    return null;
  }

  // Get the optimized image URL for cards (200x200)
  const optimizedImageUrl = getResizedImageUrl(data.PublicData_y?.profileImageUrl, 200);

  const profileId = data.uid || data.AuthorId;

  const displayCategory = (categoryKey) => {
    return t(`components.CompanyCard.categories.${categoryKey}`);
  };

  return (
    <Link 
      to={`${window.location.pathname.startsWith('/en') ? '/en' : ''}/profile/${profileId}`}
      className={`company-card`}
      onClick={(e) => {
        e.preventDefault();
        handleCardClick(company);
      }}
    >
      <div className="card-body">
        <div className="card-header">
          {optimizedImageUrl ? (
            <div className="profile-image-wrapper">
              <img 
                src={optimizedImageUrl} 
                alt={t('components.CompanyCard.accessibility.profileImage')}
                className="profile-image" 
                onError={(e) => {
                  e.target.onerror = null; 
                  e.target.src = 'https://via.placeholder.com/60';
                }}
              />
            </div>
          ) : (
            <div className="profile-image-placeholder" data-testid="building-icon">
              <FaBuilding color="white" aria-label={t('components.CompanyCard.accessibility.placeholderImage')} />
            </div>
          )}
          <h3 className="card-title">
            <span>{data.Title || t('components.CompanyCard.defaults.title')}</span>
          </h3>
        </div>
        <p className="card-text">
          {data.PublicData_y?.location?.address || t('components.CompanyCard.defaults.address')}
        </p>
        {data.PublicData_y?.description && (
          <p className="card-description">
            {data.PublicData_y.description}
          </p>
        )}
        <div className="card-categories">
          {data.PublicData_y?.category?.length > 0 && (
            <>
              <span className="category-tag">
                {displayCategory(data.PublicData_y.category[0])}
              </span>
              {data.PublicData_y.category.length > 1 && (
                <span className="more-categories">
                  +{data.PublicData_y.category.length - 1}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CompanyCard;
