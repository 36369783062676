// src/pages/LoginPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sendSignInLinkToEmail } from "firebase/auth"; // Firebase's official module
import { auth } from "../../authentication/Firebase.js"; // Your custom auth export from firebase.js
import { Form, Button, Container, Row, Col, InputGroup, Spinner, Modal } from 'react-bootstrap';
import useAuth from '../../hooks/useAuth'; // Import useAuth hook
import { Email, MarkEmailRead, ErrorOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { termsOfService } from '../TermsPage/terms.js';

import './LoginPage.css';

const LoginPage = ({ modalType = 'login', onModalTypeChange }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth(); // Use the login function from useAuth
  const [showTerms, setShowTerms] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Generate verification ID
      const verificationResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/generate-verification-id`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin,
        },
        body: JSON.stringify({ email }),
      });
      
      const { verificationId } = await verificationResponse.json();
      
      // Check if user is already logged in
      const currentUser = auth.currentUser;
      const isVerificationOnly = !!currentUser;
      
      const actionCodeSettings = {
        url: `${window.location.origin}/finishSignup?vid=${verificationId}&email=${encodeURIComponent(email)}`,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      
      // Store email in localStorage as a fallback
      window.localStorage.setItem('emailForSignIn', email);
      
      // Always store verification ID for polling
      window.localStorage.setItem(`verificationId_${verificationId}`, verificationId);
      
      // Start polling for verification status
      startVerificationCheck(verificationId);
      
      setMessage(t('pages.login.messages.linkSent'));
      setIsEmailSent(true);
      
      // Clean up old verification IDs after 1 hour
      setTimeout(() => {
        window.localStorage.removeItem(`verificationId_${verificationId}`);
      }, 3600000);
      
    } catch (error) {
      setMessage(t('pages.login.errors.generic', { error: error.message }));
    }
    setIsLoading(false);
  };

  // Add this new function to handle polling
  const startVerificationCheck = (verificationId) => {
    let checkInterval;

    const checkStatus = async () => {
      // Check if user is already logged in
      if (auth.currentUser) {
        clearInterval(checkInterval);
        window.localStorage.removeItem(`verificationId_${verificationId}`);
        navigate('/'); // Redirect to homepage instead of /dashboard
        return;
      }

      // Check if this verification process is still valid
      const storedVid = window.localStorage.getItem(`verificationId_${verificationId}`);
      if (!storedVid) {
        console.log('Verification process expired or cancelled');
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/check-verification?vid=${verificationId}`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Origin': window.location.origin,
            }
          }
        );
        const data = await response.json();
        
        if (data.verified && data.idToken) {
          // Clean up this verification ID
          window.localStorage.removeItem(`verificationId_${verificationId}`);
          
          // If verified, attempt to login with the idToken
          try {
            await login(data.idToken);
            navigate('/'); // Redirect to homepage instead of /dashboard
            return; // Stop polling
          } catch (error) {
            console.error('Error logging in:', error);
          }
        }
        
        // Continue polling if not verified
        setTimeout(checkStatus, 5000);
      } catch (error) {
        console.error('Error checking verification status:', error);
        setTimeout(checkStatus, 5000);
      }
    };

    checkInterval = setInterval(checkStatus, 5000);

    // Store the interval ID so we can clear it later if needed
    window.localStorage.setItem(`checkInterval_${verificationId}`, checkInterval);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };

  useEffect(() => {
    // Your existing code...

    return () => {
      // Cleanup function
      Object.keys(window.localStorage)
        .filter(key => key.startsWith('checkInterval_'))
        .forEach(key => {
          clearInterval(parseInt(window.localStorage.getItem(key)));
          window.localStorage.removeItem(key);
        });
    };
  }, []);

  // Update the toggleModalType function to use navigation
  const toggleModalType = () => {
    const newPath = modalType === 'login' ? '/signup' : '/login';
    navigate(newPath);
    if (onModalTypeChange) {
      onModalTypeChange(modalType === 'login' ? 'register' : 'login');
    }
  };

  return (
    <div className="login-page">
      {!isEmailSent ? (
        <div className="form-container">
          <h4 className="header-title" data-testid="login-title">
            {t(`pages.login.title.${modalType}`)}
          </h4>
          <h2 className="main-title" data-testid="login-subtitle">
            {t(`pages.login.subtitle.${modalType}`)}
          </h2>
          
          <Form onSubmit={handleEmailSignIn} className="login-form">
            <Form.Group className="mb-4">
              <Form.Label>{t('pages.login.form.email.label')}</Form.Label>
              <InputGroup className="input-group-bottom-border">
                <Form.Control
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder={t('pages.login.form.email.placeholder')}
                  required
                  disabled={isLoading}
                  className="mb-0"
                  data-testid="email-input"
                />
                <InputGroup.Text>
                  <Email className="email-icon" />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <div className="terms-container">
              <small>
                {t(`pages.login.terms.${modalType}.text`)}{' '}
                <button 
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowTerms(true);
                  }}
                  className="p-0 m-0 border-0 bg-transparent text-primary"
                  style={{ 
                    cursor: 'pointer',
                    fontWeight: 'inherit',
                    display: 'inline',
                    lineHeight: 'inherit'
                  }}
                >
                  {t(`pages.login.terms.${modalType}.linkText`)}
                </button>
              </small>
            </div>

            <Button 
              type="submit" 
              variant="primary" 
              className="w-100 submit-button"
              disabled={isLoading || !isValidEmail}
              data-testid="submit-button"
            >
              {isLoading ? (
                <>
                  <Spinner 
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  {t(`pages.login.form.button.loading.${modalType}`)}
                </>
              ) : (
                t(`pages.login.form.button.${modalType}`)
              )}
            </Button>

            <div className="toggle-container">
              <small>
                {t(`pages.login.form.toggle.${modalType}`)}
                <button 
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModalType();
                  }}
                  className="p-0 m-0 border-0 bg-transparent text-primary toggle-button"
                >
                  {t(`pages.login.form.toggle.linkText.${modalType}`)}
                </button>
              </small>
            </div>
          </Form>
        </div>
      ) : (
        <div className="success-state">
          <div className="alert alert-success">
            En inloggningslänk har skickats till din e-postadress. Vänligen kontrollera din inkorg.
          </div>
          
          <div className="success-message">
            <div className="email-icon-wrapper">
              <Email className="email-icon" />
            </div>
            
            <div className="waiting-text">
              Väntar på att du ska logga in. Gå till din e-post och klicka på länken för att logga in
              <span className="email-text">{email}</span>
            </div>
            
            <div className="next-steps">
              <h6>Vad händer nu?</h6>
              <ol>
                <li>Öppna din e-post</li>
                <li>Klicka på länken i mailet</li>
                <li>Du loggas in automatiskt</li>
              </ol>
            </div>
          </div>

          <div className="help-text">
            <ErrorOutline className="warning-icon" />
            <span>
              Hittar du inte mailet? Kolla i skräpposten eller{' '}
              <button className="inline-link" onClick={() => setIsEmailSent(false)}>
                försök igen
              </button>
            </span>
          </div>
        </div>
      )}

      {/* Terms of Service Modal */}
      <Modal 
        show={showTerms} 
        onHide={() => setShowTerms(false)}
        size="lg"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('pages.login.terms.modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {termsOfService}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTerms(false)}>
            {t('pages.login.terms.modal.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoginPage;
