import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sv from '../language/sv';
import en from '../language/en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      sv: {
        translation: sv,
      },
      en: {
        translation: en,
      },
    },
    lng: 'sv', // default language
    fallbackLng: 'sv',
    debug: process.env.NODE_ENV === 'development',
    
    interpolation: {
      escapeValue: false,
    },

    // Add detection options
    detection: {
      order: ['path', 'navigator'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
  });

// Function to change language
export const changeLanguage = (lng) => {
  return i18n.changeLanguage(lng);
};

// Function to get current language
export const getCurrentLanguage = () => {
  return i18n.language;
};

export default i18n;