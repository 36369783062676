import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ConnectProjectPage.module.css';
import PageMetaTags from '../../components/MetaTags/PageMetaTags';

const ConnectProjectPage = () => {
  const { t } = useTranslation();
  const [showEmail, setShowEmail] = useState(false);

  const handleContactClick = () => {
    if (!showEmail) {
      setShowEmail(true);
    } else {
      const email = 'info@medla.app';
      const subject = 'Anslut Projekt';
      window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    }
  };

  return (
    <>
      <PageMetaTags 
        title={t('meta.pages.connectProject.title')}
        description={t('meta.pages.connectProject.description')}
      />
      <div className={styles.container}>
        {/* Hero Section */}
        <section className={styles.hero}>
          <div className={styles.heroContent}>
            <h1>{t('pages.connectProject.hero.title')}</h1>
            <p>{t('pages.connectProject.hero.subtitle')}</p>
            <div className={styles.ctaContainer}>
              <button 
                onClick={handleContactClick}
                className="btn btn-secondary"
              >
                {showEmail ? 'info@medla.app' : t('pages.connectProject.hero.cta')}
              </button>
            </div>
          </div>
        </section>

        {/* How it Works Section */}
        <section className={styles.howItWorks}>
          <h2>{t('pages.connectProject.howItWorks.title')}</h2>
          <div className={styles.steps}>
            <div className={`${styles.step} card`}>
              <h3>{t('pages.connectProject.howItWorks.steps.anchor.title')}</h3>
              <p>{t('pages.connectProject.howItWorks.steps.anchor.description')}</p>
            </div>
            <div className={`${styles.step} card`}>
              <h3>{t('pages.connectProject.howItWorks.steps.mediate.title')}</h3>
              <p>{t('pages.connectProject.howItWorks.steps.mediate.description')}</p>
            </div>
            <div className={`${styles.step} card`}>
              <h3>{t('pages.connectProject.howItWorks.steps.measure.title')}</h3>
              <p>{t('pages.connectProject.howItWorks.steps.measure.description')}</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConnectProjectPage;
