const urlCache = new Map();

export const getResizedImageUrl = (originalUrl, size = 400) => {
  const cacheKey = `${originalUrl}_${size}`;
  if (urlCache.has(cacheKey)) {
    return urlCache.get(cacheKey);
  }
  
  if (!originalUrl) return null;
  
  try {
    const url = new URL(originalUrl);
    const BUCKET_NAME = 'medla-auth.appspot.com';
    
    // Handle both Firebase Storage and direct Storage URLs
    if (url.hostname === 'firebasestorage.googleapis.com' || url.hostname === 'storage.googleapis.com') {
      const pathParts = url.hostname === 'firebasestorage.googleapis.com' 
        ? decodeURIComponent(url.pathname.split('/o/')[1]).split('?')[0].split('/')
        : url.pathname.split('/').slice(2);
      
      const filename = pathParts.pop();
      
      // Check if we're already using an optimized image with correct size
      if (filename.includes(`_${size}x${size}.webp`)) {
        urlCache.set(cacheKey, originalUrl);
        return originalUrl;
      }
      
      // Get the closest supported size
      const supportedSizes = [200, 400, 800];
      const targetSize = supportedSizes.reduce((prev, curr) => {
        return Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev;
      });
      
      // Get filename without extension and size suffix
      const basename = filename.replace(/(_\d+x\d+)?\..*$/, '');
      
      // Construct optimized path
      const optimizedFilename = `${basename}_${targetSize}x${targetSize}.webp`;
      const optimizedPath = pathParts.join('/');
      const newPath = `${optimizedPath}/${optimizedFilename}`;
      
      // Construct and cache the transformed URL
      const transformedUrl = `https://storage.googleapis.com/${BUCKET_NAME}/${newPath}`;
      urlCache.set(cacheKey, transformedUrl);
      return transformedUrl;
    }
    
    urlCache.set(cacheKey, originalUrl);
    return originalUrl;
  } catch (error) {
    console.error('Error generating resized image URL:', error);
    urlCache.set(cacheKey, originalUrl);
    return originalUrl;
  }
};

export const getImageSizes = {
  thumbnail: 200,
  medium: 400,
  large: 800
}; 