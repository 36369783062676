import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const OffersDisplay = ({ offers, isEditMode, onAddOffer, onRemoveOffer }) => {
  const { t } = useTranslation();
  
  const validOffers = offers.filter(offer => offer.heading && offer.content);

  const handleRemoveOffer = (index, e) => {
    e.stopPropagation();
    if (window.confirm(t('components.OffersDisplay.messages.confirmDelete'))) {
      onRemoveOffer(index);
    }
  };

  return (
    <div className="offers-section">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>{t('components.OffersDisplay.title')}</h3>
        {isEditMode && validOffers.length < 5 && (
          <Button 
            variant="outline-primary" 
            size="sm" 
            onClick={onAddOffer}
            data-testid="add-offer-button"
          >
            <FaPlus className="me-2" />
            {t('components.OffersDisplay.actions.addOffer')}
          </Button>
        )}
      </div>

      {validOffers.length === 0 ? (
        <p>{t('components.OffersDisplay.messages.noOffers')}</p>
      ) : (
        <Accordion defaultActiveKey={null} alwaysOpen>
          {validOffers.map((offer, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <div className="d-flex align-items-center">
                <Accordion.Header className="flex-grow-1">
                  {offer.heading}
                </Accordion.Header>
                {isEditMode && (
                  <div className="me-2">
                    <Button
                      data-testid="remove-offer-button"
                      variant="outline-danger"
                      size="sm"
                      onClick={(e) => handleRemoveOffer(index, e)}
                      className="ms-2"
                      aria-label={t('components.OffersDisplay.actions.removeOffer')}
                    >
                      <FaTrash />
                    </Button>
                  </div>
                )}
              </div>
              <Accordion.Body>{offer.content}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default OffersDisplay;
