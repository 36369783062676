// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AppRoutes from './routes';
import '../src/styles/theme.css';
import '../src/styles/styles.css';
import { CookieConsentProvider } from './context/CookieConsentContext';
import CookieBanner from './components/CookieBanner/CookieBanner';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Sitemap from './components/Sitemap/Sitemap';
import { useTranslation } from 'react-i18next';
import { LanguageProvider } from './context/LanguageContext';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const SentryFallback = () => {
  return (
    <div className="error-boundary">
      <h2>Oops! Something went wrong</h2>
      <p>We've been notified and are working to fix the issue.</p>
    </div>
  );
};

// Create a wrapper component to handle route changes
const RouteChangeHandler = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    // Set default title for homepage
    if (location.pathname === '/') {
      document.title = `${t('meta.pages.home.title')} | Medla`;
    }
  }, [location, t]);

  return children;
};

const LegacyProfileRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchUserAndRedirect = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-by-legacy-id/${id}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }

        const data = await response.json();
        
        if (data && data.AuthorId) {
          navigate(`/profile/${data.AuthorId}`, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      } catch (error) {
        console.error('Error in fetchUserAndRedirect:', error);
        navigate('/', { replace: true });
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndRedirect();
  }, [id, navigate]);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return null;
};

function App() {
  return (
    <Sentry.ErrorBoundary fallback={SentryFallback}>
      <Router>
        <CookieConsentProvider>
          <LanguageProvider>
            <ScrollToTop />
            <RouteChangeHandler>
              <div className="App">
                <header>
                  <Header />
                </header>
                <main>
                  <Routes>
                    <Route path="/profile/:id" element={<ProfilePage />} />
                    <Route path="/c/:displayName/:id" element={<LegacyProfileRedirect />} />
                    <Route path="/c/:id" element={<LegacyProfileRedirect />} />
                    <Route path="/l/:id" element={<LegacyProfileRedirect />} />
                    <Route path="/sitemap.xml" element={<Sitemap />} />
                    <Route path="*" element={<AppRoutes />} />
                  </Routes>
                </main>
                <footer>
                  <Footer />
                </footer>
                <CookieBanner />
              </div>
            </RouteChangeHandler>
          </LanguageProvider>
        </CookieConsentProvider>
      </Router>
    </Sentry.ErrorBoundary>
  );
}

export default App;
