import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutPage.css';
import PageMetaTags from '../../components/MetaTags/PageMetaTags';

const AboutPage = () => {
  const { t } = useTranslation();

  const renderMessageWithLink = () => {
    const beforeLink = 'Trots att elektrifieringen och klimatomställningen blir allt viktigare frågor så har projekteringen bara blivit svårare, att andelen vindkraftsprojekt som fick veto ';
    const afterLink = ' är talande.';
    const linkText = 'ökade från 8% till 40% mellan 2016-2020';
    const linkUrl = 'https://web.archive.org/web/20210625073447/https://www.regeringen.se/49e6a7/contentassets/8b16a30f6de3468ab6fa640ebb23851f/en-rattssaker-vindkraftsprovning-sou-202153#page=192';

    return (
      <p>
        {beforeLink}
        <a href={linkUrl} target="_blank" rel="noopener noreferrer" className="source-link">
          {linkText}
        </a>
        {afterLink}
      </p>
    );
  };

  return (
    <>
      <PageMetaTags 
        title={t('meta.pages.about.title')}
        description={t('meta.pages.about.description')}
      />
      <div className="about-page">
        <div className="about-container">
          <h1>{t('pages.about.title')}</h1>
          <p className="mission">{t('pages.about.mission')}</p>

          <section>
            <h2>{t('pages.about.what.title')}</h2>
            <p>{t('pages.about.what.description')}</p>
          </section>

          <section>
            <h2>{t('pages.about.background.title')}</h2>
            <p>{t('pages.about.background.description')}</p>
          </section>

          <section>
            <h2>{t('pages.about.goals.title')}</h2>
            <ul>
              {['goal1', 'goal2', 'goal3'].map((goal, index) => (
                <li key={index}>{t(`pages.about.goals.${goal}`)}</li>
              ))}
            </ul>
          </section>

          <section>
            <h2>{t('pages.about.contact.title')}</h2>
            <p>{t('pages.about.contact.description')}</p>
          </section>

          <section className="message">
            <h2>{t('pages.about.message.title')}</h2>
            {renderMessageWithLink()}
            <p>{t('pages.about.message.impact')}</p>
            
            <h3>{t('pages.about.challenges.title')}</h3>
            <ul>
              {['challenge1', 'challenge2'].map((challenge, index) => (
                <li key={index}>{t(`pages.about.challenges.${challenge}`)}</li>
              ))}
            </ul>

            <p>{t('pages.about.message.conclusion')}</p>
            <p>{t('pages.about.message.future')}</p>
          </section>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
