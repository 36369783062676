import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './SearchPageMapComponent.css';
import { MdLocationOn } from 'react-icons/md'; // or any other icon you prefer

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapComponent = ({ 
  companies, 
  icons = [], 
  onMarkerClick, 
  center, 
  zoom, 
  disableClick = false, 
  onBoundsChanged, 
  staticMap = false, 
  currentUser,
  isProfilePage = false,
  isMapView,
  highlightedCompanyId
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markers = useRef([]); // Add this to track markers
  const [mapLoaded, setMapLoaded] = useState(false);
  const [initialBoundsFit, setInitialBoundsFit] = useState(false);
  const resizeTimeout = useRef(null); // Add this for debouncing
  const initialPositionSet = useRef(false);
  const [webGLError, setWebGLError] = useState(false);

  // Check WebGL support
  useEffect(() => {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) {
      setWebGLError(true);
      return;
    }
  }, []);

  useEffect(() => {
    if (map.current || webGLError) return; // Don't initialize if WebGL error or map exists

    try {
      const isMobile = window.innerWidth <= 768;

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: center || [15.4515, 59.4044],
        zoom: zoom,
        interactive: true,
        dragPan: true,
        scrollZoom: false, // Start with scroll zoom disabled
        dragRotate: true,
        keyboard: true,
        doubleClickZoom: true,
        touchZoomRotate: true,
        failIfMajorPerformanceCaveat: true // This will force fallback if WebGL performance is poor
      });

      // Add zoom controls
      map.current.addControl(new mapboxgl.NavigationControl({
        showCompass: false
      }), 'top-right');

      // Handle mobile-specific settings together
      if (isMobile) {
        map.current.scrollZoom.enable();
      }

      // Only disable other interactions if explicitly static
      if (staticMap) {
        map.current.dragPan.disable();
        map.current.boxZoom.disable();
        map.current.dragRotate.disable();
        map.current.keyboard.disable();
        map.current.doubleClickZoom.disable();
        map.current.touchZoomRotate.disable();
      }

      map.current.on('load', () => {
        setMapLoaded(true);
      });

    } catch (error) {
      console.error('MapBox initialization error:', error);
      setWebGLError(true);
    }
  }, [center, zoom, staticMap]);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

// Create custom marker images from SVG
const createMarkerImage = (color, size, isHover = false) => {
  const svg = `
    <svg width="${size}" height="${size}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow dx="0" dy="1" stdDeviation="${isHover ? '2' : '1'}" flood-color="#000" flood-opacity="${isHover ? '0.5' : '0.3'}"/>
        </filter>
        ${!isHover ? `
        <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style="stop-color:${color};stop-opacity:1" />
          <stop offset="100%" style="stop-color:${color};stop-opacity:0.8" />
        </linearGradient>
        ` : ''}
      </defs>
      <path d="M12 0C7.58 0 4 3.58 4 8c0 5.25 8 13 8 13s8-7.75 8-13c0-4.42-3.58-8-8-8z" 
        fill="${isHover ? 'white' : 'url(#grad)'}" 
        filter="url(#shadow)"
      />
      <circle cx="12" cy="8" r="3" fill="${isHover ? color : 'white'}" />
    </svg>
  `;
  
  const img = new Image();
  img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
  return img;
};

// Load marker images with theme colors
const normalMarker = createMarkerImage('#295376', 32); // accent-color
const highlightedMarker = createMarkerImage('#295376', 50, true); // accent-color, hover state

normalMarker.onload = () => {
  if (!map.current.hasImage('marker-normal')) {
    map.current.addImage('marker-normal', normalMarker);
  }
};

highlightedMarker.onload = () => {
  if (!map.current.hasImage('marker-highlighted')) {
    map.current.addImage('marker-highlighted', highlightedMarker);
  }
};


    // Convert companies to GeoJSON
    const geojson = {
      type: 'FeatureCollection',
      features: companies.map(company => {
        const data = company.data || company._source || company || {};
        const location = data.PublicData_y?.location;
        const companyId = data.uid || data.AuthorId;
        
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [location?.longitude, location?.latitude]
          },
          properties: {
            id: companyId,
            isHighlighted: companyId === highlightedCompanyId
          }
        };
      }).filter(feature => 
        feature.geometry.coordinates[0] && 
        feature.geometry.coordinates[1]
      )
    };

    // Add source and layers if they don't exist
    if (!map.current.getSource('companies')) {
      map.current.addSource('companies', {
        type: 'geojson',
        data: geojson,
      });

      // Add layer for non-highlighted markers
      map.current.addLayer({
        id: 'markers',
        type: 'symbol',
        source: 'companies',
        layout: {
          'icon-image': 'marker-normal',
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'bottom'
        },
        paint: {
          'icon-opacity': [
            'case',
            ['get', 'isHighlighted'],
            0, // Hide if highlighted
            1  // Show if not highlighted
          ]
        }
      });

      // Add layer for highlighted markers
      map.current.addLayer({
        id: 'markers-highlighted',
        type: 'symbol',
        source: 'companies',
        layout: {
          'icon-image': 'marker-highlighted',
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'bottom'
        },
        paint: {
          'icon-opacity': [
            'case',
            ['get', 'isHighlighted'],
            1, // Show if highlighted
            0  // Hide if not highlighted
          ]
        }
      });

      // Add hover effects
      map.current.on('mouseenter', 'markers', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseleave', 'markers', () => {
        map.current.getCanvas().style.cursor = '';
      });

      map.current.on('mouseenter', 'markers-highlighted', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseleave', 'markers-highlighted', () => {
        map.current.getCanvas().style.cursor = '';
      });

      // Add click handlers
      map.current.on('click', 'markers', (e) => {
        if (e.features.length > 0) {
          const companyId = e.features[0].properties.id;
          onMarkerClick(companyId);
        }
      });

      map.current.on('click', 'markers-highlighted', (e) => {
        if (e.features.length > 0) {
          const companyId = e.features[0].properties.id;
          onMarkerClick(companyId);
        }
      });
    } else {
      // Update existing source data
      map.current.getSource('companies').setData(geojson);
    }

  }, [companies, highlightedCompanyId, mapLoaded, onMarkerClick]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (map.current) {
        if (map.current.getLayer('markers-highlighted')) {
          map.current.removeLayer('markers-highlighted');
        }
        if (map.current.getLayer('markers')) {
          map.current.removeLayer('markers');
        }
        if (map.current.getSource('companies')) {
          map.current.removeSource('companies');
        }
      }
    };
  }, []);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

    // Handle resize events with debouncing
    const handleResize = () => {
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
      
      resizeTimeout.current = setTimeout(() => {
        if (map.current) {
          map.current.resize();
          
          // Only refit bounds for static maps
          if (staticMap && !initialBoundsFit) {
            const bounds = new mapboxgl.LngLatBounds();
            let hasValidCoordinates = false;

            companies.forEach((company) => {
              const data = company.data || company._source || company || {};
              const location = data.PublicData_y?.location;
              if (location?.latitude && location?.longitude) {
                bounds.extend([location.longitude, location.latitude]);
                hasValidCoordinates = true;
              }
            });

            icons.forEach(icon => {
              if (icon.position?.length === 2) {
                bounds.extend(icon.position);
                hasValidCoordinates = true;
              }
            });

            if (hasValidCoordinates) {
              map.current.fitBounds(bounds, { 
                padding: 50, 
                maxZoom: zoom || 15,
                duration: 0 // Disable animation
              });
            }
          }
        }
      }, 100);
    };

    if (staticMap) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (staticMap) {
        window.removeEventListener('resize', handleResize);
      }
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
    };
  }, [companies, icons, mapLoaded, onMarkerClick, disableClick, zoom, staticMap, currentUser, isProfilePage, highlightedCompanyId]);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

    // Only add bounds change listener if not static and onBoundsChanged is provided
    if (!staticMap && onBoundsChanged) {
      const handleBoundsChanged = () => {
        const bounds = map.current.getBounds();
        onBoundsChanged(bounds, companies);
      };

      map.current.on('moveend', handleBoundsChanged);
      map.current.on('zoomend', handleBoundsChanged);

      return () => {
        if (map.current) {
          map.current.off('moveend', handleBoundsChanged);
          map.current.off('zoomend', handleBoundsChanged);
        }
      };
    }
  }, [mapLoaded, staticMap, onBoundsChanged, companies]);

  // Add this effect to handle map resize when isMapView changes
  useEffect(() => {
    if (map.current && mapLoaded) {
      // Small delay to ensure DOM has updated
      setTimeout(() => {
        map.current.resize();
        
        // Only set center and zoom the first time map becomes visible
        if (isMapView && !initialPositionSet.current) {
          map.current.setCenter(center || [15.4515, 59.4044]);
          map.current.setZoom(zoom || 4.5);
          initialPositionSet.current = true;
        }
      }, 100);
    }
  }, [isMapView, mapLoaded, center, zoom]);

  if (webGLError) {
    return (
      <div 
        className="map-fallback"
        style={{
          width: '100%',
          height: staticMap ? '300px' : '400px',
          backgroundColor: '#f5f5f5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center'
        }}
      >
        <MdLocationOn style={{ fontSize: '48px', color: '#295376', marginBottom: '16px' }} />
        <h3 style={{ margin: '0 0 8px 0', color: '#333' }}>Map Unavailable</h3>
        <p style={{ margin: '0', color: '#666' }}>
          Your browser doesn't support WebGL, which is required for the interactive map.
          Try using a modern browser or updating your current one.
        </p>
      </div>
    );
  }

  return (
    <div 
      ref={mapContainer} 
      data-testid="map-container"
      className={`
        ${staticMap ? 'static-map-container' : ''}
        ${isMapView !== undefined ? 'search-page-map' : ''}
        ${isMapView ? 'map-view' : ''}
      `.trim()}
      style={{ 
        width: '100%', 
        height: staticMap ? undefined : '400px',
        position: 'relative'
      }} 
    />
  );
};

export default MapComponent;
