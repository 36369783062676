import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { termsOfService } from './terms';
import './TermsPage.css';

const TermsPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms-page">
      <div className="terms-container">
        <h1>{t('pages.terms.title')}</h1>
        <div className="terms-content">
          {termsOfService.split('\n').map((paragraph, index) => {
            if (paragraph.trim().startsWith('#')) {
              return <h2 key={index}>{paragraph.replace('#', '').trim()}</h2>;
            }
            return paragraph.trim() ? <p key={index}>{paragraph}</p> : null;
          })}
        </div>
      </div>
    </div>
  );
};

export default TermsPage;