import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './SitemapPage.css';

const SitemapPage = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-all-index-docs?index=medla_users&page=1&size=1000`);
        const data = await response.json();
        const publishedCompanies = data.docs
          .filter(company => {
            const source = company._source || company;
            return (source.uid || source.AuthorId) && 
                   source.State_y === 'published';
          })
          .map(company => {
            const source = company._source || company;
            return {
              id: source.uid || source.AuthorId,
              title: source.Title || source.DisplayName || 'Company Profile'
            };
          });
        setCompanies(publishedCompanies);
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  if (loading) {
    return <div className="sitemap-loading">{t('common.loading')}</div>;
  }

  return (
    <div className="sitemap-page">
      <h1>{t('pages.sitemap.title')}</h1>
      
      <section className="sitemap-section">
        <h2>{t('pages.sitemap.mainPages')}</h2>
        <ul>
          <li><Link to="/">{t('pages.home.title')}</Link></li>
          <li><Link to="/search">{t('pages.search.title')}</Link></li>
        </ul>
      </section>

      <section className="sitemap-section">
        <h2>{t('pages.sitemap.projects')}</h2>
        <ul>
          <li><Link to="/projekt/velinga">Velinga Vindkraftpark</Link></li>
          <li><Link to="/projekt/tomasliden">Tomasliden Vindkraftpark</Link></li>
        </ul>
      </section>

      <section className="sitemap-section">
        <h2>{t('pages.sitemap.companies')}</h2>
        <ul className="companies-list">
          {companies.map(company => (
            <li key={company.id}>
              <Link to={`/profile/${company.id}`}>{company.title}</Link>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default SitemapPage; 