import React, { createContext, useState, useContext, useEffect } from 'react';

const CookieConsentContext = createContext();

export function CookieConsentProvider({ children }) {
  const [hasConsent, setHasConsent] = useState(null);

  useEffect(() => {
    const storedConsent = localStorage.getItem('cookieConsent');
    console.log('Initial stored consent:', storedConsent);
    
    if (storedConsent === 'true' || storedConsent === 'false') {
      setHasConsent(storedConsent === 'true');
    } else {
      setHasConsent(null);
    }
  }, []);

  const giveConsent = () => {
    console.log('Giving consent');
    localStorage.setItem('cookieConsent', 'true');
    setHasConsent(true);
  };

  const withdrawConsent = () => {
    console.log('Withdrawing consent');
    localStorage.setItem('cookieConsent', 'false');
    setHasConsent(false);
  };

  console.log('CookieConsent Provider State:', { hasConsent });

  return (
    <CookieConsentContext.Provider value={{ hasConsent, giveConsent, withdrawConsent }}>
      {children}
    </CookieConsentContext.Provider>
  );
}

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }
  return context;
}; 