import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form, Button, Alert, Dropdown } from 'react-bootstrap';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import CategorySection from './CategorySection';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { FaSearch, FaTimes } from 'react-icons/fa';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const ProfileEditForm = ({
  userProfile,
  setUserProfile,
  publicData,
  setPublicData,
  handlePublicDataChange,
  handleSave,
  handleImageUpload,
  uploading,
  message,
  handleCategoryChange
}) => {
  const { t } = useTranslation();
  
  const [visibleOffers, setVisibleOffers] = useState(() => {
    let count = 0;
    for (let i = 1; i <= 5; i++) {
      if (publicData[`offer${i}`] || publicData[`offerHeading${i}`]) {
        count = i;
      }
    }
    return Math.max(1, count);
  });

  const isOfferComplete = (offerNum) => {
    return publicData[`offerHeading${offerNum}`]?.trim() && 
           publicData[`offer${offerNum}`]?.trim();
  };

  const areAllOffersValid = () => {
    for (let i = 1; i <= visibleOffers; i++) {
      const hasHeading = !!publicData[`offerHeading${i}`]?.trim();
      const hasDescription = !!publicData[`offer${i}`]?.trim();
      if ((hasHeading && !hasDescription) || (!hasHeading && hasDescription)) {
        return false;
      }
    }
    return true;
  };

  const addNewOffer = () => {
    if (visibleOffers < 5 && isOfferComplete(visibleOffers)) {
      setVisibleOffers(visibleOffers + 1);
    }
  };

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);

  useEffect(() => {
    if (!geocoderRef.current && geocoderContainerRef.current) {
      try {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          types: 'address',
          countries: 'se',
          language: 'sv',
          placeholder: t('components.ProfileEditForm.fields.company.address'),
          marker: false,
        });

        geocoderRef.current = geocoder;

        geocoder.addTo(geocoderContainerRef.current);

        geocoder.on('result', (e) => {
          const { result } = e;
          const address = result.place_name;
          const [longitude, latitude] = result.center;

          handlePublicDataChange({
            target: {
              name: 'location',
              value: {
                address,
                longitude,
                latitude
              }
            }
          });
        });

        if (publicData?.location?.address) {
          geocoder.setInput(publicData.location.address);
        }

        geocoder.on('error', () => {
          console.error('Geocoder error');
        });

      } catch (error) {
        console.error('Error initializing geocoder:', error);
      }
    }

    return () => {
      if (geocoderRef.current) {
        geocoderRef.current.onRemove();
        geocoderRef.current = null;
      }
    };
  }, [publicData?.location?.address, handlePublicDataChange, t]);

  const [orgNumberSuggestions, setOrgNumberSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const searchCompanies = async (query) => {
    try {
      setIsSearching(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/search-companies?q=${encodeURIComponent(query)}`
      );
      const data = await response.json();
      
      // Extract company suggestions from the search results
      const suggestions = data.items?.map(item => {
        // Extract org number using regex (format: XXXXXX-XXXX)
        const orgNumberMatch = item.snippet.match(/\d{6}-\d{4}/);
        
        // Clean up company name by removing everything after " - "
        const cleanTitle = item.title.split(' - ')[0].trim();
        
        return {
          name: cleanTitle,
          orgNumber: orgNumberMatch ? orgNumberMatch[0] : null,
          description: item.snippet
        };
      })
      .filter(item => item.orgNumber) // Keep only items with org numbers
      .reduce((unique, item) => {
        // Remove duplicates based on organization number
        const exists = unique.find(u => u.orgNumber === item.orgNumber);
        if (!exists) {
          unique.push(item);
        }
        return unique;
      }, []);

      setOrgNumberSuggestions(suggestions || []);
    } catch (error) {
      console.error('Error searching companies:', error);
      setOrgNumberSuggestions([]);
    } finally {
      setIsSearching(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => searchCompanies(query), 300),
    []
  );

  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const dropdownRef = useRef(null);

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (orgNumberSuggestions.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedSuggestionIndex(prev => 
          prev < orgNumberSuggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedSuggestionIndex(prev => prev > 0 ? prev - 1 : 0);
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedSuggestionIndex >= 0) {
          const selectedSuggestion = orgNumberSuggestions[selectedSuggestionIndex];
          handlePublicDataChange({
            target: {
              name: 'companyNumber',
              value: selectedSuggestion.orgNumber
            }
          });
          setOrgNumberSuggestions([]);
          setSelectedSuggestionIndex(-1);
        }
        break;
      case 'Tab':
        if (orgNumberSuggestions.length > 0) {
          e.preventDefault();
          setSelectedSuggestionIndex(prev => 
            prev < orgNumberSuggestions.length - 1 ? prev + 1 : 0
          );
        }
        break;
      case 'Escape':
        setOrgNumberSuggestions([]);
        setSelectedSuggestionIndex(-1);
        break;
      default:
        break;
    }
  };

  // Reset selected index when suggestions change
  useEffect(() => {
    setSelectedSuggestionIndex(-1);
  }, [orgNumberSuggestions]);

  // Scroll selected item into view
  useEffect(() => {
    if (selectedSuggestionIndex >= 0 && dropdownRef.current) {
      const selectedElement = dropdownRef.current.children[selectedSuggestionIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        });
      }
    }
  }, [selectedSuggestionIndex]);

  const companyNumberFormGroup = (
    <Form.Group controlId="companyNumber" className="mb-3 position-relative">
      <Form.Label>{t('components.ProfileEditForm.fields.company.orgNumber')}</Form.Label>
      <Form.Control
        type="text"
        name="companyNumber"
        value={publicData.companyNumber || ''}
        onChange={(e) => {
          handlePublicDataChange(e);
          if (e.target.value.length >= 3 && /[a-zA-Z]/.test(e.target.value)) {
            debouncedSearch(e.target.value);
          } else {
            setOrgNumberSuggestions([]);
          }
        }}
        onKeyDown={handleKeyDown}
        placeholder="Skriv företagsnamn för förslag"
        autoComplete="off"
        aria-expanded={orgNumberSuggestions.length > 0}
        aria-haspopup="listbox"
        aria-controls="org-number-suggestions"
      />
      {isSearching && (
        <span 
          className="spinner-border spinner-border-sm" 
          role="status"
          style={{
            position: 'absolute',
            right: '12px',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
        />
      )}
      {orgNumberSuggestions.length > 0 && (
        <div 
          ref={dropdownRef}
          className="dropdown-menu-company-number"
          role="listbox"
          id="org-number-suggestions"
        >
          {orgNumberSuggestions.map((suggestion, index) => (
            <div
              key={index}
              className={`dropdown-item ${index === selectedSuggestionIndex ? 'active' : ''}`}
              onClick={() => {
                handlePublicDataChange({
                  target: {
                    name: 'companyNumber',
                    value: suggestion.orgNumber
                  }
                });
                setOrgNumberSuggestions([]);
                setSelectedSuggestionIndex(-1);
              }}
              role="option"
              aria-selected={index === selectedSuggestionIndex}
              tabIndex={0}
            >
              <strong>{suggestion.name}</strong>
              <small className="text-muted d-block">{suggestion.orgNumber}</small>
            </div>
          ))}
        </div>
      )}
    </Form.Group>
  );

  // Add state for preview image and upload status
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  // Modified handler for image upload that shows preview
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setUploadError(null);
        
        // Validate file type and size
        if (!file.type.startsWith('image/')) {
          setUploadError('Invalid file type. Please upload an image.');
          return;
        }

        // 5MB limit
        if (file.size > 5 * 1024 * 1024) {
          setUploadError('File too large. Maximum size is 5MB.');
          return;
        }

        // Create preview URL
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
        
        // Call the original handler
        await handleImageUpload(event);
      } catch (error) {
        console.error('Error handling image:', error);
        setUploadError(error.message || 'Error uploading image');
        // Clear preview on error
        if (imagePreview) {
          URL.revokeObjectURL(imagePreview);
          setImagePreview(null);
        }
      }
    }
  };

  // Cleanup preview URL when component unmounts
  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  // Add function to clear profile image
  const handleClearImage = async () => {
    if (window.confirm(t('components.ProfileEditForm.confirmations.deleteImage'))) {
      setImagePreview(null);
      setPublicData(prev => ({
        ...prev,
        profileImageUrl: ''
      }));
    }
  };

  return (
    <Form>
      {message && (
        <Alert variant={message.type} className="mt-3">
          {message.text}
        </Alert>
      )}

      <h2 className="form-section-header mb-4">{t('components.ProfileEditForm.sections.companyDetails')}</h2>

      <Form.Group controlId="profileImage" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.image.label')}</Form.Label>
        <div className="profile-image-controls">
          {(imagePreview || publicData.profileImageUrl) && (
            <div className="profile-image-preview mb-2">
              <img 
                src={imagePreview || publicData.profileImageUrl} 
                alt="Preview" 
                className="img-thumbnail"
              />
              <Button
                variant="danger"
                size="sm"
                onClick={handleClearImage}
                disabled={uploading}
                aria-label="Delete image"
              >
                <FaTimes />
              </Button>
            </div>
          )}
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            disabled={uploading}
            data-testid="image-upload-input"
          />
          {uploading && <p>{t('components.ProfileEditForm.status.loading')}</p>}
          {uploadError && (
            <Alert variant="danger" className="mt-2">
              {uploadError}
            </Alert>
          )}
        </div>
      </Form.Group>

      <Form.Group controlId="Title" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.company.name')}</Form.Label>
        <Form.Control
          type="text"
          name="Title"
          value={userProfile.Title || ''}
          onChange={(e) => setUserProfile(prev => ({ ...prev, Title: e.target.value }))}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.company.address')}</Form.Label>
        <div 
          ref={geocoderContainerRef} 
          className="geocoder-container"
          style={{ minHeight: '38px' }}
        />
      </Form.Group>

      <Form.Group controlId="companyContact" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.contact.person')}</Form.Label>
        <Form.Control
          type="text"
          name="companyContact"
          value={publicData.companyContact || ''}
          onChange={handlePublicDataChange}
        />
      </Form.Group>

      <Form.Group controlId="contactNumber" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.contact.phone')}</Form.Label>
        <Form.Control
          type="text"
          name="contactNumber"
          value={publicData.contactNumber || ''}
          onChange={handlePublicDataChange}
        />
      </Form.Group>

      <Form.Group controlId="contactEmail" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.contact.email')}</Form.Label>
        <Form.Control
          type="email"
          name="contactEmail"
          value={publicData.contactEmail || ''}
          onChange={handlePublicDataChange}
        />
      </Form.Group>

      {companyNumberFormGroup}

      <h2 className="form-section-header mb-4">{t('components.ProfileEditForm.sections.about')}</h2>

      <Form.Group className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.company.description')}</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          name="description"
          value={publicData.description || ''}
          onChange={handlePublicDataChange}
          placeholder={t('components.ProfileEditForm.fields.services.placeholders.description')}
        />
      </Form.Group>

      <div className="mb-4">
        <Form.Label>{t('components.ProfileEditForm.fields.categories.label')}</Form.Label>
        <CategorySection 
          isEditMode={true}
          categories={publicData.category || []}
          onCategoryChange={handleCategoryChange}
        />
      </div>

      <h2 className="form-section-header mb-4">{t('components.ProfileEditForm.sections.services')}</h2>

      {[...Array(visibleOffers)].map((_, index) => {
        const offerNum = index + 1;
        const isIncomplete = (publicData[`offerHeading${offerNum}`]?.trim() || 
                            publicData[`offer${offerNum}`]?.trim()) && 
                            !isOfferComplete(offerNum);
        
        return (
          <div key={offerNum} className="offer-group mb-4">
            <Form.Group className="mb-2">
              <Form.Label>{t('components.ProfileEditForm.fields.services.title', { number: offerNum })}</Form.Label>
              <Form.Control
                type="text"
                name={`offerHeading${offerNum}`}
                value={publicData[`offerHeading${offerNum}`] || ''}
                onChange={handlePublicDataChange}
                placeholder={t('components.ProfileEditForm.fields.services.placeholders.title')}
                isInvalid={isIncomplete}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('components.ProfileEditForm.fields.services.description', { number: offerNum })}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name={`offer${offerNum}`}
                value={publicData[`offer${offerNum}`] || ''}
                onChange={handlePublicDataChange}
                placeholder={t('components.ProfileEditForm.fields.services.placeholders.description')}
                isInvalid={isIncomplete}
              />
              {isIncomplete && (
                <Form.Text className="text-danger">
                  {t('components.ProfileEditForm.validation.serviceRequired')}
                </Form.Text>
              )}
            </Form.Group>
            {offerNum > 1 && (
              <Button 
                variant="outline-danger" 
                size="sm"
                onClick={() => {
                  if (window.confirm(t('components.ProfileEditForm.validation.confirmDelete'))) {
                    const newPublicData = { ...publicData };
                    for (let i = offerNum; i < 5; i++) {
                      newPublicData[`offerHeading${i}`] = publicData[`offerHeading${i + 1}`] || '';
                      newPublicData[`offer${i}`] = publicData[`offer${i + 1}`] || '';
                    }
                    newPublicData[`offerHeading5`] = '';
                    newPublicData[`offer5`] = '';
                    setPublicData(newPublicData);
                    setVisibleOffers(visibleOffers - 1);
                  }
                }}
                className="mt-2"
              >
                {t('components.ProfileEditForm.actions.removeService', { number: offerNum })}
              </Button>
            )}
          </div>
        );
      })}
      
      {visibleOffers < 5 && (
        <Button 
          variant="outline-primary" 
          onClick={addNewOffer}
          className="mt-2"
          disabled={!isOfferComplete(visibleOffers)}
        >
          {t('components.ProfileEditForm.actions.addService')}
        </Button>
      )}
    </Form>
  );
};

export default ProfileEditForm;
