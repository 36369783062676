import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './ProjectMap.css';
import { TbWindmill } from "react-icons/tb"; // Replace the FaWind import
import ReactDOM from 'react-dom'; // Add this import

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const BASE_RADIUS_KM = 50; // Radius in kilometers

const MapComponent = ({ 
  companies, 
  icons = [], 
  onMarkerClick, 
  center, 
  zoom = 7.0, // Set a default zoom level
  disableClick = false, 
  onBoundsChanged, 
  staticMap = false, 
  currentUser,
  isProfilePage = false,
  isMapView,
  highlightedCompanyId,
  onHover,
  projectMapTooltip
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markers = useRef([]); // Add this to track markers
  const [mapLoaded, setMapLoaded] = useState(false);
  const [initialBoundsFit, setInitialBoundsFit] = useState(false);
  const resizeTimeout = useRef(null); // Add this for debouncing
  const initialPositionSet = useRef(false);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: center || [15.4515, 59.4044],
      zoom: zoom,
      maxZoom: 18,
      minZoom: 5,
      pitch: 0,
      bearing: 0,
      interactive: true,
      dragPan: true,
      scrollZoom: false, // Disable scroll zoom
      dragRotate: true,
      keyboard: true,
      doubleClickZoom: true,
      touchZoomRotate: true,
      preserveDrawingBuffer: true // Add this to prevent mobile rendering issues
    });

    // Add zoom controls
    map.current.addControl(new mapboxgl.NavigationControl({
      showCompass: false // Set to false to only show zoom controls
    }), 'top-right');



    // Only disable other interactions if explicitly static
    if (staticMap) {
      map.current.dragPan.disable();
      map.current.boxZoom.disable();
      map.current.dragRotate.disable();
      map.current.keyboard.disable();
      map.current.doubleClickZoom.disable();
      map.current.touchZoomRotate.disable();
    }

    map.current.on('load', () => {
      setMapLoaded(true);
    });
  }, [center, zoom, staticMap]);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

// Create custom marker images from SVG
const createMarkerImage = (color, size, isHover = false) => {
    const svg = `
      <svg width="${size}" height="${size}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feDropShadow dx="0" dy="1" stdDeviation="${isHover ? '2' : '1'}" flood-color="#000" flood-opacity="${isHover ? '0.5' : '0.3'}"/>
          </filter>
          ${!isHover ? `
          <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style="stop-color:${color};stop-opacity:1" />
            <stop offset="100%" style="stop-color:${color};stop-opacity:0.8" />
          </linearGradient>
          ` : ''}
        </defs>
        <path d="M12 0C7.58 0 4 3.58 4 8c0 5.25 8 13 8 13s8-7.75 8-13c0-4.42-3.58-8-8-8z" 
          fill="${isHover ? 'white' : 'url(#grad)'}" 
          filter="url(#shadow)"
        />
        <circle cx="12" cy="8" r="3" fill="${isHover ? color : 'white'}" />
      </svg>
    `;
    
    const img = new Image();
    img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
    return img;
  };
  
  // Load marker images with theme colors
  const normalMarker = createMarkerImage('#295376', 32); // accent-color
  const highlightedMarker = createMarkerImage('#295376', 50, true); // accent-color, hover state
  
  normalMarker.onload = () => {
    if (!map.current.hasImage('marker-normal')) {
      map.current.addImage('marker-normal', normalMarker);
    }
  };
  
  highlightedMarker.onload = () => {
    if (!map.current.hasImage('marker-highlighted')) {
      map.current.addImage('marker-highlighted', highlightedMarker);
    }
  };
  
    // Convert companies to GeoJSON
    const geojson = {
      type: 'FeatureCollection',
      features: companies.map(company => {
        const data = company.data || company._source || company || {};
        const location = data.PublicData_y?.location;
        const companyId = data.uid || data.AuthorId;
        
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [location?.longitude, location?.latitude]
          },
          properties: {
            id: companyId,
            isHighlighted: companyId === highlightedCompanyId
          }
        };
      }).filter(feature => 
        feature.geometry.coordinates[0] && 
        feature.geometry.coordinates[1]
      )
    };

    // Add source and layers if they don't exist
    if (!map.current.getSource('companies')) {
      map.current.addSource('companies', {
        type: 'geojson',
        data: geojson,
      });

      // Add layer for non-highlighted markers
      map.current.addLayer({
        id: 'markers',
        type: 'symbol',
        source: 'companies',
        layout: {
          'icon-image': 'marker-normal',
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'bottom'
        },
        paint: {
          'icon-opacity': [
            'case',
            ['get', 'isHighlighted'],
            0, // Hide if highlighted
            1  // Show if not highlighted
          ]
        }
      });

      // Add layer for highlighted markers
      map.current.addLayer({
        id: 'markers-highlighted',
        type: 'symbol',
        source: 'companies',
        layout: {
          'icon-image': 'marker-highlighted',
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'bottom'
        },
        paint: {
          'icon-opacity': [
            'case',
            ['get', 'isHighlighted'],
            1, // Show if highlighted
            0  // Hide if not highlighted
          ]
        }
      });

      // Add hover effects
      map.current.on('mouseenter', 'markers', (e) => {
        if (onHover && e.features.length > 0) {
          const companyId = e.features[0].properties.id;
          onHover(companyId);
        }
      });

      map.current.on('mouseleave', 'markers', () => {
        if (onHover) {
          onHover(null);
        }
      });

      map.current.on('mouseenter', 'markers-highlighted', (e) => {
        if (onHover && e.features.length > 0) {
          const companyId = e.features[0].properties.id;
          onHover(companyId);
        }
      });

      map.current.on('mouseleave', 'markers-highlighted', () => {
        if (onHover) {
          onHover(null);
        }
      });

      // Add click handlers
      map.current.on('click', 'markers', (e) => {
        if (e.features.length > 0) {
          const companyId = e.features[0].properties.id;
          onMarkerClick(companyId);
        }
      });

      map.current.on('click', 'markers-highlighted', (e) => {
        if (e.features.length > 0) {
          const companyId = e.features[0].properties.id;
          onMarkerClick(companyId);
        }
      });
    } else {
      // Update existing source data
      map.current.getSource('companies').setData(geojson);
    }

  }, [companies, highlightedCompanyId, mapLoaded, onMarkerClick, onHover]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (map.current) {
        if (map.current.getLayer('markers-highlighted')) {
          map.current.removeLayer('markers-highlighted');
        }
        if (map.current.getLayer('markers')) {
          map.current.removeLayer('markers');
        }
        if (map.current.getSource('companies')) {
          map.current.removeSource('companies');
        }
      }
    };
  }, []);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

    // Handle resize and rerender
    const handleResize = () => {
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
      
      resizeTimeout.current = setTimeout(() => {
        if (map.current) {
          map.current.resize();
          
          // Only refit bounds for static maps with multiple points
          if (staticMap && (companies.length > 1 || icons.length > 1)) {
            const bounds = new mapboxgl.LngLatBounds();
            let hasValidCoordinates = false;

            companies.forEach((company) => {
              const data = company.data || company._source || company || {};
              const location = data.PublicData_y?.location;
              if (location?.latitude && location?.longitude) {
                bounds.extend([location.longitude, location.latitude]);
                hasValidCoordinates = true;
              }
            });

            icons.forEach(icon => {
              if (icon.position?.length === 2) {
                bounds.extend(icon.position);
                hasValidCoordinates = true;
              }
            });

            if (hasValidCoordinates) {
              map.current.fitBounds(bounds, { 
                padding: { top: 50, bottom: 50, left: 50, right: 50 },
                maxZoom: zoom,
                duration: 0
              });
            }
          } else {
            // For single points or non-static maps, maintain the specified center and zoom
            map.current.setCenter(center || [15.4515, 59.4044]);
            map.current.setZoom(zoom);
          }
        }
      }, 100);
    };

    // Only add scroll listener for static maps
    if (staticMap) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (staticMap) {
        window.removeEventListener('resize', handleResize);
      }
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
    };
  }, [companies, icons, mapLoaded, zoom, staticMap, center]);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

    // Only add bounds change listener if not static and onBoundsChanged is provided
    if (!staticMap && onBoundsChanged) {
      const handleBoundsChanged = () => {
        const bounds = map.current.getBounds();
        onBoundsChanged(bounds);
      };

      map.current.on('moveend', handleBoundsChanged);
      map.current.on('zoomend', handleBoundsChanged);

      // Initial bounds check
      handleBoundsChanged();

      // Cleanup listener
      return () => {
        if (map.current) {
          map.current.off('moveend', handleBoundsChanged);
          map.current.off('zoomend', handleBoundsChanged);
        }
      };
    }
  }, [mapLoaded, staticMap, onBoundsChanged]);

  // Add this effect to handle map resize when isMapView changes
  useEffect(() => {
    if (map.current && mapLoaded) {
      // Small delay to ensure DOM has updated
      setTimeout(() => {
        map.current.resize();
        
        // Only set center and zoom the first time map becomes visible
        if (isMapView && !initialPositionSet.current) {
          map.current.setCenter(center || [15.4515, 59.4044]);
          map.current.setZoom(zoom || 4.5);
          initialPositionSet.current = true;
        }
      }, 100);
    }
  }, [isMapView, mapLoaded, center, zoom]);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

    // Clear any existing project icon markers
    const existingMarkers = document.querySelectorAll('.project-icon-marker');
    existingMarkers.forEach(marker => {
      marker.remove();
    });
  }, [companies, mapLoaded, center, icons]);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

    // Add source and layer for the radius circle if they don't exist
    if (!map.current.getSource('radius-point')) {
      map.current.addSource('radius-point', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: center || [15.4515, 59.4044]
          }
        }
      });

      // Get all layers and find the first symbol layer
      const layers = map.current.getStyle().layers;
      const firstSymbolId = layers.find(layer => layer.type === 'symbol')?.id;

      map.current.addLayer({
        id: 'radius-circle',
        type: 'circle',
        source: 'radius-point',
        paint: {
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, BASE_RADIUS_KM * 0.02,
            1, BASE_RADIUS_KM * 0.04,
            2, BASE_RADIUS_KM * 0.08,
            3, BASE_RADIUS_KM * 0.16,
            4, BASE_RADIUS_KM * 0.32,
            5, BASE_RADIUS_KM * 0.64,
            6, BASE_RADIUS_KM * 1.28,
            7, BASE_RADIUS_KM * 2.56,
            8, BASE_RADIUS_KM * 5.12,
            9, BASE_RADIUS_KM * 10.24,
            10, BASE_RADIUS_KM * 20.48
          ],
          'circle-radius-transition': {
            duration: 0
          },
          'circle-color': '#007cbf',
          'circle-opacity': 0.2,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#007cbf'
        }
      }, firstSymbolId);
    } else {
      // Update the source data when center changes
      map.current.getSource('radius-point').setData({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: center || [15.4515, 59.4044]
        }
      });
    }

    return () => {
      if (map.current) {
        if (map.current.getLayer('radius-circle')) {
          map.current.removeLayer('radius-circle');
        }
        if (map.current.getSource('radius-point')) {
          map.current.removeSource('radius-point');
        }
      }
    };
  }, [mapLoaded, center]);

  useEffect(() => {
    if (!mapLoaded || !map.current) return;

    // Remove any existing center marker
    const existingCenterMarker = document.querySelector('.center-windmill-marker');
    if (existingCenterMarker) {
      existingCenterMarker.remove();
    }

    // Create windmill marker element
    const markerElement = document.createElement('div');
    markerElement.className = 'center-windmill-marker';
    markerElement.style.cssText = `
      color: var(--primary-color);
      font-size: 32px;
      background-color: white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--shadow-medium);
      cursor: pointer;
    `;
    
    // Render the Tabler windmill icon
    ReactDOM.render(<TbWindmill />, markerElement);

    // Create a popup but don't add it to the map immediately
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 25,
      className: 'project-popup'
    }).setHTML(`
      <div style="
        display: flex;
        align-items: center;
        gap: 8px;
      ">

          <path d="M12 6v6l4 2" />
          <circle cx="12" cy="12" r="10" />
        </svg>
        <span>${projectMapTooltip || 'Vindkraftsprojekt'}</span>
      </div>
    `);

    // Add the marker to the map
    const marker = new mapboxgl.Marker({
      element: markerElement,
      anchor: 'center'
    })
      .setLngLat(center || [15.4515, 59.4044])
      .addTo(map.current);

    // Add hover events
    markerElement.addEventListener('mouseenter', () => {
      popup.setLngLat(center || [15.4515, 59.4044]).addTo(map.current);
    });
    
    markerElement.addEventListener('mouseleave', () => {
      popup.remove();
    });

  }, [mapLoaded, center, projectMapTooltip]);

  return (
    <div 
      ref={mapContainer} 
      data-testid="map-container"
      className={`
        ${staticMap ? 'static-map-container' : ''}
        ${isMapView !== undefined ? 'search-page-map' : ''}
        ${isMapView ? 'map-view' : ''}
      `.trim()}
      style={{ 
        width: '100%', 
        height: staticMap ? undefined : '400px',
        position: 'relative',
        cursor: 'default !important'
      }} 
    />
  );
};

export default MapComponent;
