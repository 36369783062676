// src/pages/FinishSignUp.js
import React, { useEffect, useState, useCallback } from "react";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import useAuth from '../../hooks/useAuth'; // Import useAuth instead of AuthContext
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // Add this import

const FinishSignUp = () => {
  const [status, setStatus] = useState("verifying");
  const navigate = useNavigate();
  const { login } = useAuth(); // Use the login function from useAuth
  const { t } = useTranslation(); // Add this hook

  const completeVerification = useCallback(async (vid, idToken) => {
    if (vid) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/complete-verification`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ verificationId: vid, idToken })
        });
        if (!response.ok) {
          throw new Error('Failed to complete verification');
        }
        console.log("Verification completed successfully");
      } catch (error) {
        console.error("Error completing verification:", error);
      }
    }
  }, []);

  const handleEmailLink = useCallback(async (vid) => {
    const auth = getAuth();
    const urlParams = new URLSearchParams(window.location.search);
    let email = urlParams.get('email');
    
    if (!email) {
      // Fallback to localStorage if not in URL
      email = window.localStorage.getItem('emailForSignIn');
    }

    if (!email) {
      console.error("Email not found in URL or localStorage");
      setStatus("error");
      return;
    }

    try {
      console.log("Attempting to sign in with email link. Email:", email);
      const result = await signInWithEmailLink(auth, email, window.location.href);
      
      // Clear the email from storage after successful verification
      window.localStorage.removeItem('emailForSignIn');
      
      const idToken = await result.user.getIdToken();
      console.log("ID Token obtained:", idToken.substring(0, 10) + "...");
      
      await completeVerification(vid, idToken);
      
      try {
        await login(idToken);
        setStatus("verified");
        // Remove navigation to dashboard
        // navigate('/dashboard');
      } catch (loginError) {
        console.error("Detailed login error:", loginError);
        setStatus("error");
      }
    } catch (error) {
      console.error("Detailed error during sign-in:", error);
      setStatus("error");
    }
  }, [completeVerification, login]);

  const checkVerificationStatus = useCallback(async (vid) => {
    try {
      console.log(`Checking verification status for VID: ${vid}`);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-verification?vid=${vid}`);
      const data = await response.json();
      console.log(`Verification status response:`, data);
      
      if (data.verified) {
        if (data.idToken) {
          try {
            await login(data.idToken);
            setStatus("verified");
            // Remove navigation to dashboard
          } catch (loginError) {
            console.error("Error during login:", loginError);
            setStatus("error");
          }
        } else {
          console.error("No idToken received with verification");
          setStatus("error");
        }
      } else {
        console.log("Verification not complete. Checking again in 5 seconds...");
        setTimeout(() => checkVerificationStatus(vid), 5000);
      }
    } catch (error) {
      console.error("Error checking verification status:", error);
      setStatus("error");
    }
  }, [login]);

  useEffect(() => {
    const auth = getAuth();
    const urlParams = new URLSearchParams(window.location.search);
    let vid = urlParams.get('vid');
    
    console.log("FinishSignUp component mounted. URL:", window.location.href);
    console.log("VID:", vid);
    
    if (isSignInWithEmailLink(auth, window.location.href)) {
      console.log("Detected sign-in with email link");
      handleEmailLink(vid);
    } else if (vid) {
      console.log("VID detected, checking verification status");
      checkVerificationStatus(vid);
    } else {
      console.log('Not a sign-in link and no VID, redirecting to login...');
      navigate("/login");
    }
  }, [handleEmailLink, checkVerificationStatus, navigate]);

  return (
    <div className="home-page">
      <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '100vh'}}>
        {status === "verifying" && (
          <>
            <h2 className="text-center">{t('pages.finishregister.verifying.title')}</h2>
            <Spinner animation="border" role="status" className="my-3">
              <span className="visually-hidden">{t('pages.sitemap.loading')}</span>
            </Spinner>
          </>
        )}
        {status === "verified" && (
          <>
            <h2 className="text-center">{t('pages.finishregister.verified.title')}</h2>
            <p className="text-center">{t('pages.finishregister.verified.message')}</p>
            <p className="text-center">
              {t('pages.finishregister.verified.continueMessage')}{' '}
              <Link to="/">{t('pages.finishregister.verified.here')}</Link>{' '}
              {t('pages.finishregister.verified.continueInWindow')}
            </p>
          </>
        )}
        {status === "error" && (
          <>
            <h2 className="text-center">{t('pages.finishregister.error.title')}</h2>
            <p className="text-center">{t('pages.finishregister.error.message')}</p>
            <Link to="/login" className="text-center d-block">
              {t('pages.finishregister.error.backToLogin')}
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default FinishSignUp;
