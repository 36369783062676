import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import './ProfileComponents.css';

export const CATEGORY_DISPLAY_NAMES = {
  'bygg_och_konstruktion': 'Bygg & Konstruktion',
  'teknik_och_underhall': 'Teknik & Underhåll',
  'transport_och_fordon': 'Transport & Fordon',
  'affarstjanster_och_support': 'Affärstjänster & Support',
  'restaurang_och_catering': 'Restaurang & Catering',
  'boende': 'Boende'
};

const CategorySection = ({ isEditMode, categories = [], onCategoryChange }) => {
  const { t } = useTranslation();
  
  const categoryOptions = Object.keys(CATEGORY_DISPLAY_NAMES).map(key => ({
    value: key,
    label: t(`components.CategorySection.categories.${key}.label`),
    examples: t(`components.CategorySection.categories.${key}.examples`)
  }));

  const displayCategory = (categoryKey) => {
    return t(`components.CategorySection.categories.${categoryKey}.label`);
  };

  return (
    <div className="category-section mt-4 mb-4">
      <h4>{t('components.CategorySection.title')}</h4>
      {isEditMode ? (
        <Select
          isMulti
          options={categoryOptions}
          value={categoryOptions.filter(option => 
            categories?.includes(option.value)
          )}
          onChange={onCategoryChange}
          placeholder={t('components.CategorySection.selectPlaceholder')}
          classNamePrefix="react-select"
          formatOptionLabel={({ label, examples }) => (
            <div>
              <div>{label}</div>
              <div>
                <small className="text-muted">
                  {t('components.CategorySection.examples')} {examples}
                </small>
              </div>
            </div>
          )}
        />
      ) : (
        <div className="category-tags">
          {categories && categories.length > 0 ? (
            categories.map((cat, index) => (
              <span key={index} className="category-tag">
                {displayCategory(cat)}
              </span>
            ))
          ) : (
            <p>{t('components.CategorySection.noCategories')}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default CategorySection;
