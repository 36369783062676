import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import './HelpPage.css';
import PageMetaTags from '../../components/MetaTags/PageMetaTags';

const HelpPage = () => {
  const { t } = useTranslation();
  const [isEmailRevealed, setIsEmailRevealed] = useState(false);
  const email = 'support@medla.app';

  const handleContactSupport = () => {
    if (!isEmailRevealed) {
      setIsEmailRevealed(true);
      return;
    }

    const subject = 'Support Request';
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  };

  return (
    <>
      <PageMetaTags 
        title={t('meta.pages.help.title')}
        description={t('meta.pages.help.description')}
      />
      <div className="help-page">
        <div className="help-container">
          <div className="help-header">
            <div className="help-title-group">
              <h1>{t('pages.help.title')}</h1>
              <p className="help-subtitle">{t('pages.help.description')}</p>
            </div>
          </div>
          
          <div className="help-content">
            <p>{t('pages.help.content.text3')}</p>
            <Button 
              variant="primary" 
              onClick={handleContactSupport}
              className="help-contact-button"
            >
              {isEmailRevealed ? email : t('pages.help.content.contactButton')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpPage;